export const SKIP_ANIMATION = true;
///


export const TITLES = {
    "Поступило": "Поступило",
    "Выписан": "Выписано/Эвакуировано",
}

export const ALL_IN_STATUS = "Поступило";

export const ALL_STATUSES = [
    [ALL_IN_STATUS], // Поступило
    ["Выписан", "Эвакуирован", "Направлен на ВВК", "Направлен на консультацию/диагностику", "Отказ от госпитализации"], // Выписано/Эвакуировано
];

//

export const TITLES_CURRENT = {
    "Находится на лечении": "На лечении",
}

export const ALL_IN_STATUS_CURRENT = "Находится на лечении";

export const ALL_STATUSES_CURRENT = [
    [ALL_IN_STATUS_CURRENT], // На лечении
];
