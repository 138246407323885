import React from 'react';
import 'dayjs/locale/ru';

import { styled } from '@mui/material';

import { SKIP_ANIMATION } from '../../constants';
import { PieChart, useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
}));

function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
        <StyledText x={left + width / 2} y={top + height / 2}>
            {children}
        </StyledText>
    );
}

const PieBlockComponent = ({ data, total, onItemClick = () => { }, highlightedItem }) => {

    let pieSeries = {
        outerRadius: 110,
        data: data,
        arcLabel: params => "" + params.value,
        innerRadius: 50,
        cornerRadius: 3,
        paddingAngle: 2,
        highlightScope: {
            fade: 'global',
            highlight: 'item',
        },
        highlighted: {
            additionalRadius: 10,
            innerRadius: 50,
            dataIndex: 1,
        },
        faded: {
            color: 'gray',
            // additionalRadius: -30,
        },
        sortingValues: "desc",

    }

    let slotProps = {
        legend: {
            direction: 'row',
            position: { vertical: 'bottom', horizontal: 'center' },
            padding: 0,
            // itemGap: 3,
        },
        loadingOverlay: { message: 'Рассчёт данных' },
        noDataOverlay: { message: 'Нет данных' },
    };

    return <>
        <PieChart
            skipAnimation={SKIP_ANIMATION}
            series={[pieSeries]}
            slotProps={slotProps}
            highlightedItem={highlightedItem}
            width={380}
            height={300}
            onItemClick={onItemClick}
            margin={{ top: 100, bottom: 100, left: 100, right:100 }}
        // loading={true}
        >
            <PieCenterLabel>{total || ""}</PieCenterLabel>
        </PieChart>
    </>;
}

export const PieBlock = React.memo(PieBlockComponent);
