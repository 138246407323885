// import { useDispatch } from "react-redux";

import {
    // Button,
    Box,
} from "@mui/material";

// import PrintIcon from '@mui/icons-material/Print';
// import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import {
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarQuickFilter,
    // useGridApiContext,
} from '@mui/x-data-grid-pro';

// import {
//     setPrintList,
// } from "../../statisticSlice";

// import { appStorage } from "../../../../helpers";


export function CustomToolbar() {
    // let authentication = appStorage.getItem('authentication') || {};
    // let userId = authentication.idPersonnel || "";

    // const dispatch = useDispatch();
    // const apiRef = useGridApiContext();

    return (<>
        <GridToolbarContainer sx={{ mb: 1 }}>
            <GridToolbarQuickFilter />
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarExport
                printOptions={{
                    disableToolbarButton: true,
                    hideToolbar: true,
                    hideFooter: true,
                    // getRowsToExport: (wer, wefr, wferr) => {
                    // console.log(wer, wefr, wferr);
                    // return ["444"];
                    // },
                    // fields: columnsToPrint,
                }}
                csvOptions={{
                    utf8WithBom: true,
                    delimiter: ";",
                }}
                slotProps={{
                    tooltip: { title: 'Экспорт' },
                    button: { variant: 'outlined' },
                }}
            />
        </GridToolbarContainer>
    </>
    );
}