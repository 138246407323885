import React, { useState } from 'react';
import { cards } from '../app/thema';
import { urlMilitaryUsers, urlMilitaryOrganizations } from '../common/helper/UrlRouter';

import { Grid, Avatar } from '@mui/material';

import { BtnCard, BtnCardImg } from '../components/Cards';


/**
 * Виджет формы управление сервером
 * @returns виджет
 */
export default function ManagementTools() {
    const model = {
        militaryUsers: {
            thema: cards.collection1,
            fontSize: '20pt',
            img: './img/MilitoryUsers.jpg',
            title: 'Пользователи системы',
            href: urlMilitaryUsers
        },
        militaryOrganizations: {
            thema: cards.collection1,
            fontSize: '20pt',
            img: './img/MilitaryOrganizations.jpg',
            title: 'Медицинские организации',
            href: urlMilitaryOrganizations
        }
    };

    return (
        <Grid
            sx={{
                mt: '15px',
            }}
            container
        >
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
            <Grid
                item xs={12} sm={12} md={8} lg={4} xl={4}
            >
                <Grid
                    container spacing={2}
                >
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.militaryUsers.thema} fontSize={model.militaryUsers.fontSize} img={model.militaryUsers.img}
                            title={model.militaryUsers.title} href={model.militaryUsers.href}
                        />
                    </Grid>
                    <Grid
                        item xs={12} sm={12} md={12} lg={12} xl={12}
                    >
                        <BtnCardImg
                            thema={model.militaryOrganizations.thema} fontSize={model.militaryOrganizations.fontSize} 
                            img={model.militaryOrganizations.img} title={model.militaryOrganizations.title}
                            href={model.militaryOrganizations.href}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                }}
                item xs={0} sm={0} md={2} lg={4} xl={4}
            />
        </Grid>
    )
}
