import React, { memo, useEffect } from "react";

import {
    Card,
    Box,
} from "@mui/material";

import { Filters2 } from "./components/Filters2";

import "./styles.css";
import { StatisticContent } from "./StatisticContent";

import {
    ALL_IN_STATUS,
    ALL_IN_STATUS_CURRENT,
    ALL_STATUSES,
    ALL_STATUSES_CURRENT,
    TITLES,
    TITLES_CURRENT,
} from "./constants";

import {
    resetStatisticData,
    selectFilterValues,
    selectStatisticData,
    selectTableCurrent,
    sendGetStatisticData,
    sendGetTableCurrent,
} from "./statisticSlice";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

const StatisticComponent = () => {
    const dispatch = useDispatch();

    const filterValues = useSelector(selectFilterValues);
    let statisticData = useSelector(selectStatisticData);
    let tableCurrent = useSelector(selectTableCurrent);

    useEffect(() => {
        if (filterValues.from && filterValues.to) {
            dispatch(sendGetStatisticData({
                data: {
                    "dateStart": dayjs(filterValues.from || undefined).toISOString(),
                    "dateEnd": dayjs(filterValues.to || undefined).toISOString(),
                },
            }));
        }
        dispatch(sendGetTableCurrent());
        setTimeout(() => {
            // setHasGetData(true);
        }, 2000);

        return () => {
            dispatch(resetStatisticData());
        };
    }, []);

    return <>

        <Box className="no-print"
            sx={{
                mt: '15px',
            }}
        >
            <Card
                sx={{
                    mx: '5px',
                    pl: '10px',
                    borderRadius: '10px 0 0 10px',
                    // backgroundColor: "#aec194",
                    borderWidth: 0,
                }}
                variant="outlined"
            >
                <Box
                    sx={{
                        display: "flex",
                    }}
                >
                    <Box sx={{
                        width: "280px",
                        overflow: "auto",
                        height: "calc(100vh - 98px)",
                        // p: 1,
                    }}
                    >
                        {/* <Filters /> */}
                        <Filters2 />
                    </Box>

                    <Box sx={{
                        width: "calc(100% - 280px);",
                    }}>
                        {/* <Typography sx={{
                            textAlign: 'center',
                            width: "100%"
                        }}
                            variant="h4"
                        >
                            Статистика
                        </Typography> */}

                        <Box
                            sx={{
                                display: 'block',
                                // flexDirection: 'row',
                                height: "calc(100vh - 98px)",
                                width: 'auto',
                                justifyContent: "center",
                                overflow: "auto",
                                p: 1,
                                backgroundColor: "#aec194", //"#508a85",
                            }}
                        >
                            <StatisticContent
                                statuses={ALL_STATUSES}
                                tableData={statisticData}
                                titles={TITLES}
                                allInStatus={ALL_IN_STATUS}
                            />
                            <StatisticContent
                                statuses={ALL_STATUSES_CURRENT}
                                tableData={tableCurrent}
                                titles={TITLES_CURRENT}
                                allInStatus={ALL_IN_STATUS_CURRENT}

                            />
                        </Box>
                    </Box>

                </Box>
            </Card>
        </Box >
    </>;
}


export const Statistic = memo(StatisticComponent);
