import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { urlManagement } from '../common/helper/UrlRouter';

import { fields, buttons, cards } from '../app/thema';
import { GetUsersAsync } from '../common/api/Management';
import roleJson from '../shared/roles.json';

import {
    Grid, Card, Typography
} from '@mui/material';
import { UserCard } from '../components/Cards';
import { UserEditDlg } from '../components/Dialogs';
import { TextFld, PasswordFld } from '../components/Fields';
import { StartIconBtn, EndIconBtn, IconBtn } from '../components/Buttons';

import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import PersonAddAltOutlineIcon from '@mui/icons-material/PersonAddAlt';
import VpnKeyIcon from '@mui/icons-material/VpnKey';


/**
 * 
 * @returns 
 */
export default function FormaByUsers() {
    const navigate = useNavigate();
    const [userCard, setUserCard] = useState([]);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(true);

    // Загрузка данных
    const handleDataLoader = async () => {
        let res = await GetUsersAsync();
        let users = [];

        res.users.map((elem) => {
            let rl = roleJson.find(e => e.id = elem.role);
            users.push({
                key: elem.id,
                title: elem.fio,
                mRank: elem.mRankS,
                login: elem.login,
                lic: elem.key,
                isBlock: elem.blocked,
                role: rl,
            });
        });
        setUserCard(users);
    }
    const handleClickAccept = () => {
        formik.values.fio = '';
        formik.values.login = '';
        formik.values.password = '';
        formik.values.mRank = '';
        formik.values.lic = '';
        formik.role = '';

        setIsUpdate(true);
        setOpen(true);
    }
    const handleClickUpdate = (id) => {
        let elem = userCard.find(e => e.key === id);

        formik.values.fio = elem.title;
        formik.values.login = elem.login;
        formik.values.password = elem.password;
        formik.values.mRank = elem.mRank;
        formik.values.lic = elem.lic;
        formik.values.role = elem.role;

        setIsUpdate(true);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClickUserDlg = () => {
        // Обновлениеи данных
        if (isUpdate) {
            console.log('update', formik.values);
            return;
        }

        // Добавления данных
        console.log('add', formik.values);
    }
    const handleUndoOut = () => {
        navigate(urlManagement);
    }

    const handleFormAction = (values) => {
    };

    useEffect(() => {
        handleDataLoader();
    }, []);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Пользователи системы',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryUser.jpg',
            title: '',
            href: '',
        },
        undoBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
        },
        keyBtn: {
            thema: buttons.collection1,
            icon: <VpnKeyIcon />
        },
        addBtn: {
            thema: buttons.collection1,
            icon: <PersonAddAltOutlineIcon />
        }
    }
    const context = {
        fio: '',
        mRank: '',
        login: '',
        password: '',
        lic: '',
        role: '',
    }
    const validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    // Формирование карточки пользователя
    const CardItem = (props) => {
        const { title, mRank, role, login, lic, isBlock, id } = props;
        return (
            <Grid
                item xs={12} sm={6} md={4} lg={3} xl={3}
            >
                <UserCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    href={model.cardItem.href} title={title} mRank={mRank} role={role} login={login} lic={lic}
                    block={isBlock} handleClick={() => handleClickUpdate(id)}
                />
            </Grid>
        )
    }

    return (
        <>
            <Grid
                sx={{
                    mt: '5px',
                    px: '10px',
                }}
                spacing={2}
                container
            >
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Card
                        sx={{
                            py: '15px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container
                        >
                            <Grid
                                sx={{
                                    pl: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.undoBtn.thema} icon={model.undoBtn.icon} handleClick={handleUndoOut}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'center',
                                    alignContent: 'center',
                                }}
                                item xs={9} sm={9} md={9} lg={9} xl={9}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'right',
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.keyBtn.thema} icon={model.keyBtn.icon}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    pr: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.addBtn.thema} icon={model.addBtn.icon} handleClick={handleClickAccept}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {userCard.map((elem) => {
                    return (
                        <CardItem
                            block={elem.isBlock} formik={formik}
                            key={elem.key} id={elem.key} title={elem.title} mRank={elem.mRank} role={elem.role.label}
                            login={elem.login} lic={elem.lic}
                        />
                    )
                })}
            </Grid>
            <UserEditDlg
                formik={formik} open={open} isUpdate={isUpdate}
                roles={roleJson}
                handleClose={handleClose} handleClick={handleClickUserDlg}
            />
        </>
    )
}