import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { UserCheck } from '../common/helper/Cheking';
import { Upload } from '../features/upload';


export default function PUpload(props) {
    const navigate = useNavigate();

    useEffect(() => {
        UserCheck(navigate);
    }, []);

    return (
        <>
            <Upload />
        </>
    )
}
