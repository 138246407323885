import React, { memo, useEffect, useMemo, useState } from "react";

import { ruRU } from '@mui/x-data-grid-pro/locales';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    Card,
    Grid,
    Typography,
    Box,
    Menu,
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
} from "@mui/material";

import PrintIcon from '@mui/icons-material/Print';
import ArrowRightIcon from '@mui/icons-material/Assignment';

import {
    gridClasses,
    DataGridPro,
    useGridApiRef,
    GridActionsCellItem,
} from '@mui/x-data-grid-pro';

import { CustomToolbar } from "./components/CustomToolbar";

import { PrintList } from "./components/PrintList";

import {
    sendGetPSOData,
    selectPSOData,
    selectLoading,
    resetPSOData,
} from "./PSOSlice";

// import { fields } from '../../app/thema';

import { PrintTpl_5 as PrintTpl5 } from "../history/components/PrintTpl_5";
import { PrintTpl_7 as PrintTpl7 } from "../history/components/PrintTpl_7";

import "./styles.css";
import { appStorage } from "../../helpers";
import { urlHistory } from "../../common/helper/UrlRouter";
import { sendGetPatientData } from "../history/historySlice";

const fixSettings = (settings = {}, apiRef) => {
    let fixedSettings = { ...settings };

    // some fixes for users LS data
    let currentFilterModel = apiRef?.current?.state?.filter?.filterModel?.items || [];
    let columnsLookup = apiRef?.current?.store?.value?.columns?.lookup || {};
    let hasBrokenFilters = false;

    currentFilterModel.forEach(item => {
        let filterOperators = columnsLookup[item.field]?.filterOperators || [];
        let cleanFilterOperators = filterOperators.map(item => item.value) || [];

        if (!cleanFilterOperators.includes(item.operator)) {
            console.log("hasBrokenFilters", cleanFilterOperators, item.operator);
            hasBrokenFilters = true;
        }
    });

    if (hasBrokenFilters) {
        delete fixedSettings.filter.filterModel;
    }

    // Удалаяем пустой объект columnVisibilityModel, иначе будет считаться что нуно показать все колонки
    if (settings.columns && settings.columns.hasOwnProperty("columnVisibilityModel")) {
        if (Object.keys(settings.columns.columnVisibilityModel).length === 0) {
            delete fixedSettings.columns.columnVisibilityModel;
        }
    }
    return fixedSettings;
}


const PSOComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);
    const [tplId, setTplId] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [printData, setPrintData] = useState({});

    const openPrint = Boolean(anchorEl);

    const apiRef = useGridApiRef();
    let PSOData = useSelector(selectPSOData);
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    let initialRows = useMemo(() => [], []);
    let columns = [];
    let columnsToHide = {};
    let columnsToPrint = [];
    let columnsToGroupChange = [];

    let authentication = appStorage.getItem('authentication') || {};
    let userId = authentication.idPersonnel || "";
    let userSettingsLS = appStorage.getItem(userId) || {};
    let PSOSettingsLS = fixSettings(userSettingsLS.PSOSettings, apiRef) || {};

    const [PSOSettings, setPSOSettings] = useState(PSOSettingsLS);


    if (PSOData.table) {
        initialRows = PSOData.table.rows;
        columns = [...PSOData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
            if (column.toPrint) {
                columnsToPrint.push(column.field);
            }
            if (column.groupChange) {
                columnsToGroupChange.push(column.field);
            }
        })
    }

    useEffect(() => {
        dispatch(sendGetPSOData());
    }, [dispatch]);

    useEffect(() => {
        let columnsToHide = {};

        if (PSOData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            })
            setColumnVisibilityModel(columnsToHide)

            initialRows = PSOData.table.rows;
            setRows(initialRows);
        }
    }, [PSOData.table]);


    useEffect(() => {
        return () => {
            dispatch(resetPSOData());
        };
    }, []);

    useEffect(() => {
        if (initialRows && apiRef?.current?.restoreState != null && PSOSettings != null && initialRows.length) {
            setTimeout(() => {
                apiRef?.current?.restoreState(fixSettings(PSOSettings, apiRef));
            }, 200);
        }
    }, [apiRef, initialRows, PSOSettings]);


    const handleStateChange = (eventType) => {
        console.log("eventType", eventType);
        let currentState = apiRef.current.exportState();

        let PSOSettings = {
            sorting: currentState.sorting,
            filter: currentState.filter,
            // pinnedColumns: currentState.pinnedColumns,
            columns: {
                columnVisibilityModel: currentState.columns.columnVisibilityModel,
                // orderedFields: currentState.columns.orderedFields,
                // dimensions: currentState.columns.dimensions,
            },
        };


        if (currentState && currentState.columns) {
            appStorage.setItem(userId, {
                ...userSettingsLS,
                "PSOSettings": fixSettings(PSOSettings, apiRef),
            });
        }
    }

    const handleRowClick = (data, rwer, wttgwe) => {
        const e300_form_type = data.row.e300_form_type || "Ранение";

        /**
         * @todo вынести в константы и везде использовать
         */
        const types = {
            "Ранение": "injury",
            "Травма": "trauma",
            "Заболевание": "diseases",
            "Ядерное": "nuclear",
            "Бактериальное": "bacterial",
            "Химическое": "chemical",
            "Ожог": "burn",
            "Отморожение": "frostbite",
            "Инфекция": "infectious",
            "Смерть": "death",
        };

        const type = types[e300_form_type] || "injury";
        if (data.row && data.row.jp_token_number && data.row.e300_id) {
            navigate(`/registration/${type}/${data.row.jp_token_number}/${data.row.jp_id_personnel}/${data.row.e300_id}`);
        } else {
            alert("Нет данных [jp_token_number или e300_id]");
        }
    }

    if (apiRef.current?.subscribeEvent) {
        apiRef.current.subscribeEvent("filterModelChange", () => handleStateChange("filterModelChange"));
        apiRef.current.subscribeEvent("columnVisibilityModelChange", () => handleStateChange("columnVisibilityModelChange"));
        apiRef.current.subscribeEvent("sortModelChange", () => handleStateChange("sortModelChange"));
    }
    const handlePrint = (tpl) => {
        setTplId(tpl);
        setAnchorEl(null);
        setTimeout(() => {
            window.print();
        }, 600);
    }

    const handleClickPrintMenu = (event, row) => {
        // setOpenPrint(!openPrint);

        dispatch(sendGetPatientData({
            data: {
                id: row.jp_id_personnel,
            },
            success: (data) => {
                let patientData = data.personal;
                let currentEvent = patientData.events?.findIndex(event => event.id === row.e300_id);
                let currentHistoryItem = patientData.events[currentEvent]?.history?.find(history => history.id === row.h300_id) || {};

                currentHistoryItem = {
                    ...currentHistoryItem,
                    militaryRank: patientData.militaryRank
                };

                let printData = {
                    patientData,
                    currentHistoryItem,
                    currentEvent,
                    // modalData,
                };

                setPrintData(printData);
            }
        }))

        setAnchorEl(event.currentTarget);
    };
    const handleClickPrint = (tpl, modal) => {
        handlePrint(tpl);
    }

    columns.unshift({
        field: 'actions',
        type: 'actions',
        headerName: '',
        sortable: false,
        disableReorder: true,
        editable: false,
        disableColumnMenu: true,
        width: 45,
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
            return [
                <GridActionsCellItem
                    icon={<PrintIcon />}
                    label="Печать"
                    className="textPrimary"
                    onClick={(event) => handleClickPrintMenu(event, row)}
                    color="inherit"
                />,
            ];
        },
    },)

    const handleClosePrintMenu = () => {
        setAnchorEl(null);
    };

    return <>
        {tplId === 5 ? <PrintTpl5 printData={printData} /> : null}
        {tplId === 7 ? <PrintTpl7 printData={printData} /> : null}
        {<PrintList />}
        <div className="no-print">
            <Grid
                sx={{
                    mt: '15px',
                }}
                container
            >
                <Menu
                    open={openPrint}
                    anchorEl={anchorEl}
                    onClose={handleClosePrintMenu}
                >
                    <MenuList disablePadding>
                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={() => handleClickPrint(5)}
                        >
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="Форма 12" />
                        </MenuItem>

                        <MenuItem
                            sx={{ pl: 4 }}
                            onClick={() => handleClickPrint(7)}
                        >
                            <ListItemIcon>
                                <ArrowRightIcon />
                            </ListItemIcon>
                            <ListItemText primary="Форма 98 (справка о ранении)" />
                        </MenuItem>
                    </MenuList>
                </Menu>
                <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <form>
                        <Card
                            sx={{
                                mx: '5px',
                                py: '15px',
                                pl: '10px',
                                pr: '40px',
                                borderRadius: '10px'
                            }}
                            variant="outlined"
                        >
                            <Grid
                                container spacing={2}
                            >
                                {/* <Grid
                                    style={{
                                        textAlign: 'center',
                                    }}
                                    item
                                    xs={12}
                                >
                                    <Typography
                                        variant="h4"
                                        color={fields.collection2.borderColor}
                                    >
                                        Госпитальное отделение
                                    </Typography>

                                </Grid> */}

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: "calc(100vh - 150px)",
                                        width: '100%',
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        className="PSO-table-container"
                                        sx={(theme) => ({
                                            ml: "50px",
                                            height: "calc(100vh - 150px)",
                                            width: '100%',
                                            '& .MuiDataGrid-cell--editable': {
                                                bgcolor: 'rgba(217, 243, 190, 0.3)',
                                                ...theme.applyStyles('dark', {
                                                    bgcolor: '#444',
                                                }),
                                            },
                                            '& .MuiDataGrid-cell': {
                                                borderLeft: '1px solid lightgray',
                                                cursor: 'pointer',
                                            },
                                            '& .MuiDataGrid-cell--editable input, & .MuiDataGrid-cell--editable textarea, & .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                                backgroundColor: 'rgb(245, 229, 182)',
                                                padding: "0 2px",
                                            },
                                            '& .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                                                padding: "0 10px 0 2px",
                                                fontSize: "0.875rem",
                                            },
                                            '& .MuiDataGrid-cell--editable div:nth-of-type(1) [type="datetime-local"] ': {
                                                padding: "0",
                                            },
                                            '& .MuiDataGrid-columnHeader': {
                                                "& div": {
                                                    whiteSpace: "wrap",
                                                }
                                            },
                                            '& .MuiDataGrid-row.Mui-selected': {
                                                backgroundColor: "rgba(25, 118, 210, 0.4) !important",
                                            },
                                            '& .MuiDataGrid-row.Mui-selected:hover': {
                                                backgroundColor: "rgba(25, 118, 210, 0.5) !important",
                                            },
                                            '& .MuiDataGrid-row:hover': {
                                                backgroundColor: "rgba(141, 155, 142, 0.2) !important",
                                            },
                                        })}>
                                        <DataGridPro
                                            sx={{
                                                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                                    outline: 'none',
                                                },
                                                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                                                {
                                                    outline: 'none',
                                                },
                                                [`& .${gridClasses["row--borderBottom"]}`]:
                                                {
                                                    backgroundColor: "transparent !important",
                                                },
                                                [`& .${gridClasses.columnHeaders}`]:
                                                {
                                                    backgroundColor: "#bfc4b5",
                                                },
                                                [`& .${gridClasses.columnHeaderTitle}`]:
                                                {
                                                    fontWeight: 600,
                                                    letterSpacing: "0.05071em",
                                                },
                                            }}
                                            apiRef={apiRef}
                                            getRowId={(row) => row.h300_id}
                                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                            rows={rows}
                                            columns={columns}
                                            editMode="row"
                                            getRowHeight={() => 'auto'}
                                            onRowDoubleClick={handleRowClick}
                                            slots={{
                                                toolbar: CustomToolbar.bind(this, {}),
                                            }}
                                            slotProps={{
                                                toolbar: {
                                                    showQuickFilter: true,
                                                },
                                            }}
                                            checkboxSelection
                                            disableRowSelectionOnClick
                                            loading={loading}
                                            columnVisibilityModel={columnVisibilityModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                setColumnVisibilityModel(newModel)
                                            }
                                        />
                                    </Box>
                                </Box>

                            </Grid>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </div >
    </>;
}


export const PSO = memo(PSOComponent);
