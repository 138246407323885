import { appStorage } from "../../helpers";

let authentication = appStorage.getItem('authentication') || {};
let authParams = {
    authkey: authentication.authkey || "",
    key: authentication.key || "",
}


/**
 * Класс модели запроса
 */
class RequestUserCheck {
    /**
     * Конструктор
     * @param { String } login пользователь
     * @param { String } password пароль
     */
    constructor(login, password) {
        this.login = login;
        this.password = password;
    }

    getModel() {
        return {
            login: this.login,
            password: this.password,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGet300 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestReg300 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстоятельства ранения
     * @param { String } nature Характер ранения
     * @param { String } gravityOfInjury Тяжесть ранения
     * @param { Date } dateOfVisits Дата и время поступления/обращения
     * @param { String } diagnosis Диагноз
     * @param { String } helpProvided Оказана помощь
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     
     */
    constructor(patient) {
        this.patient = patient;
    }

    getModel() {
        return {
            ...this.patient,
            dateOfIssue100: null,
            locationWhere100: "",
            number98: "",
            history98: 0,
            dateOfIssue98: null,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestUpd300 {
    /**
     * Конструктор
     * @param { String } idEvent - УИН записи события
     * @param { String } IdHistory - УИН записи истории
     * @param { String } idPersonnel УИН записи журнала личного состава
     * 
     * @param { Date } dateOfInjury Дата и время ранения
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстоятельства ранения
     * @param { String } complaint Жалобы
     * @param { String } nature Характер ранения
     * @param { String } gravityOfInjury Тяжесть ранения
     *  
     * @param { Date } dateOfVisits Дата и время поступления/обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } helpProvided Оказана помощь
     * @param { String } diagnosis Диагноз
     * @param { String } recommendations Рекомендации при выписке
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     */
    constructor({ idEvent, idHistory, ...item }
    ) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.item = item;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: this.item,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGet200 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestReg200 {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { Date } dateOfDeath Дата и время смерти
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства смерти
     * @param { String } nature Характер ранения
     * @param { String } diagnosis Паталогоанатомический диагноз
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     * @param { Date } deathCertificate Справка о смерти
     */
    constructor(patient) {
        this.patient = patient;
    }

    getModel() {
        return {
            ...this.patient,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestUpd200 {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи события
     * @param { String } idHistory УИН записи истории
     * @param { String } idPersonnel УИН записи журнала личного состава
     * 
     * @param { Date } dateOfDeath Дата и время смерти
     * @param { String } locationWhereInjury Где получил ранение
     * @param { String } circumstances Обстаятельства смерти
     * @param { String } nature Характер ранения
     * @param { Date } dateOfVisits Дата и время поступления
     * @param { String } diagnosis Паталогоанатомический диагноз
     * @param { String } locationWhere Куда эвакуирован
     * @param { Date } dateOfEvacuation Дата и время эвакуации
     * @param { String } deathCertificate Справка о смерти
     */
    constructor(idEvent, idHistory, ...item) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.item = item;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: this.item,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGetDiseases {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 * @returns Модель ответа
 */
class RequestRegDiseases {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * @param { Date } dateOfVisits Дата и время обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(patient) {

        this.patient = patient;
    }

    getModel() {
        return {
            ...this.patient,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestUpdDiseases {
    /**
     * Конструктор
     * @param { String } idEvent 
     * @param { String } idHistory
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * 
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, ...item) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.item = item;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: this.item,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestGetTrauma {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 * @returns Модель ответа
 */
class RequestRegTrauma {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * @param { Date } dateOfInjury Дата и время травмы
     * @param { String } gravityOfInjury Тяжесть травмы
     * @param { String } locationWhereInjury Где получил травму
     * @param { String } circumstances Обстаятельства травмы
     * @param { Date } dateOfVisits Дата и время обращения
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение 
     */
    constructor(patient) {
        this.patient = patient;
    }

    getModel() {
        return {
            ...this.patient,
            ...authParams,
        }
    }
}
/**
 * Класс модели запроса
 */
class RequestUpdTrauma {
    /**
     * Конструктор
     * @param { String } idEvent УИН записи из журнала события 300
     * @param { String } idHistory УИН записи из журнала ИБ
     * @param { String } idPersonnel УИН записи из журнала личного состава
     * @param { Date } dateOfInjury Дата и время травмы
     * @param { String } gravityOfInjury Тяжесть травмы
     * @param { String } locationWhereInjury Где получил травму
     * @param { String } circumstances Обстаятельства травмы
     * @param { String } locationMedicalAssistance Место оказания медицинской помщи
     * @param { String } complaint Жалобы
     * @param { String } anamnesis Анамнез
     * @param { String } objectively Обьективный статус
     * @param { String } diagnosis Диагноз
     * @param { String } pharmacotherapy Фармакотерапия
     * @param { String } recommendations Рекомендации при выписке
     * @param { Date } dateOfEvacuation Дата и время выписки
     * @param { String } typeOfDirection Вид направления
     * @param { String } specialist Специалист
     * @param { String } locationWhere Куда направлен
     * @param { String } therapy Лечение
     */
    constructor(idEvent, idHistory, ...item) {
        this.idEvent = idEvent;
        this.idHistory = idHistory;
        this.item = item;
    }

    getModel() {
        return {
            idEvent: this.idEvent,
            idHistory: this.idHistory,
            item: this.item,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestRegPersonnel {
    /**
     * Конструктор
     * @param { String } tokenNumber Номер жетона
     * @param { String } callSign Позывной
     * @param { String } surname Фамилия
     * @param { String } name Имя
     * @param { String } patronymic Отчество
     * @param { Date } birthday Дата рождения
     * @param { String } phoneNumber Номер телефона
     * @param { String } militaryUnit Воинская часть
     * @param { String } division Подразделение
     * @param { String } rota Рота
     * @param { String } platoon Взвод
     * @param { String } department Отделение
     * @param { String } militaryRank ВОенское звание
     */
    constructor(item) {
        this.item = item;
    }

    getModel() {
        return {
            ...this.item,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByTokenNumber {
    /**
     * Конструктор
     * @param { String } tokenNumber Номер жетона
     */
    constructor(tokenNumber) {
        this.tokenNumber = tokenNumber;
    }

    getModel() {
        return {
            tokenNumber: this.tokenNumber,
            ...authParams,
        }
    }
}
class RequestGenerateTokenNumber {
    getModel() {
        return {
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByCallSign {
    constructor(findByCallSign) {
        this.findByCallSign = findByCallSign;
    }

    getModel() {
        return {
            findByCallSign: this.findByCallSign,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestDocGet {
    /**
     * Конструктор
     * @param { String } idPersonnel УИН записи журнала личного состава
     */
    constructor(idPersonnel) {
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            idPersonnel: this.idPersonnel,
            ...authParams,
        };
    }
}

/**
 * Класс модели запроса
 */
class RequestFindByString {
    constructor(value) {
        this.value = value;
    }

    getModel() {
        return {
            value: this.value,
            ...authParams,
        }
    }
}

/**
 * Класс модели запроса
 */
class RequestUsers {
    getModel() {
        return {
            ...authParams,
        }
    }
}

class RequestUser {
    getModel(data) {
        return {            
            ...authParams,
            ...this.data,
        }
    }
}

class RequestHandbook300 {
    getModel() {
        return {
            ...authParams,
        }
    }
}

class RequestGetEvent {
    constructor(eventType, idPersonnel){
        this.eventType = eventType;
        this.idPersonnel = idPersonnel;
    }

    getModel() {
        return {
            eventType: this.eventType,
            idPersonnel: this.idPersonnel,
            ...authParams,
        };
    }
}

class RequestRegEvent {
    constructor(data){
        this.data = data;
    }

    getModel(){
        return {
            ...this.data,
            ...authParams,
        }
    }
}



export {
    // REST API Контроллер взаимодействия с биллингом
    RequestUserCheck,
    // REST API Контроллер взаимодействия с формами
    RequestGet300, RequestReg300, RequestUpd300, RequestGet200, RequestReg200, RequestUpd200, RequestGetDiseases,
    RequestRegDiseases, RequestUpdDiseases, RequestGetTrauma, RequestRegTrauma, RequestUpdTrauma, RequestGetEvent,
    RequestRegEvent,
    // REST API Контроллер взаимодействия с журналом личного состава
    RequestRegPersonnel, RequestFindByTokenNumber, RequestFindByCallSign, RequestFindByString,
    // REST API Контроллер для взаимодействия с документами
    RequestDocGet,
    RequestGenerateTokenNumber,
    // REST API Контроллер для управления сервером
    RequestUsers, RequestUser,

    RequestHandbook300,
}