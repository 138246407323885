import React, { memo, useEffect, useMemo, useState } from "react";

import { ruRU } from '@mui/x-data-grid-pro/locales';
import { useDispatch, useSelector } from "react-redux";

import {
    Grid,
    Typography,
    Box,
    Slide,
    Dialog,
    AppBar,
    Toolbar,
    Button,
    IconButton,
    Alert,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import {
    gridClasses,
    DataGridPro,
    useGridApiRef,
} from '@mui/x-data-grid-pro';

import { CustomToolbar } from "./components/CustomToolbar";
import {
    selectLoading,
    resetStatisticData,
} from "../statistic/statisticSlice";

import "./styles.css";

import { PieRow } from "./components/PieRow/PieRow";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


const StatisticContentComponent = ({ statuses, tableData, titles, allInStatus }) => {
    const dispatch = useDispatch();
    let [rows, setRows] = useState([]);
    let [hasGetData, setHasGetData] = useState(false);
    let [tableRows, setTableRows] = useState([]);
    const [openTable, setOpenTable] = React.useState(false);

    const apiRef = useGridApiRef();
    let loading = useSelector(selectLoading);

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

    let initialRows = useMemo(() => [], []);
    let columns = [];
    let columnsToHide = {};
    let columnsToPrint = [];
    let columnsToGroupChange = [];

    console.log(tableData);

    if (tableData.table) {
        initialRows = tableData.table.rows;
        columns = [...tableData.table.columns];

        columns.forEach(column => {
            if (column.hidden) {
                columnsToHide[column.field] = false;
            }
            if (column.toPrint) {
                columnsToPrint.push(column.field);
            }
            if (column.groupChange) {
                columnsToGroupChange.push(column.field);
            }
        })
    }

    useEffect(() => {
        let columnsToHide = {};

        if (tableData.table) {
            columns.forEach(column => {
                if (column.hidden) {
                    columnsToHide[column.field] = false;
                }
            })
            setColumnVisibilityModel(columnsToHide)

            initialRows = tableData.table.rows;
            setRows(initialRows);
        }
    }, [tableData.table]);

    useEffect(() => {
        // if (filterValues.from && filterValues.to) {
        //     dispatch(sendGetStatisticData({
        //         data: {
        //             "dateStart": dayjs(filterValues.from || undefined).toISOString(),
        //             "dateEnd": dayjs(filterValues.to || undefined).toISOString(),
        //         },
        //     }));
        // }

        setTimeout(() => {
            setHasGetData(true);
        }, 2000);

        return () => {
            dispatch(resetStatisticData());
        };
    }, []);

    // console.log(columns);
    // console.log(rows);

    const handleClickOpenTable = ({ rows }) => {

        // console.log(rows);
        setTableRows(rows);
        setOpenTable(true);
    };

    const handleCloseTable = () => {
        setOpenTable(false);
    };


    const Rows = ({ rows }) => {
        return <>
            {statuses.map((statuses, i) => {
                return <PieRow
                    key={statuses}
                    rows={rows}
                    statuses={statuses}
                    titles={titles}
                    allInStatus={allInStatus}
                    onClickOpenTable={handleClickOpenTable}
                />
            })}
        </>;
    }

    return <>
        <Box>
            {hasGetData && !rows.length ? <Alert sx={{ margin: "2% 0 2% 0", width: "auto" }}>Нет данных по выбранным фильтрам</Alert> : ""}
            {rows.length ? <Rows rows={rows} /> : null}

        </Box>

        <Dialog
            fullScreen
            open={openTable}
            onClose={handleCloseTable}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseTable}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Закрыть
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    height: "calc(100vh - 80px)",
                    width: '100%',
                    justifyContent: "center",
                }}
            >
                <Box
                    className="table-container"
                    sx={(theme) => ({
                        m: "50px",
                        mt: "10px",
                        height: "calc(100vh - 80px)",
                        width: '100%',
                        '& .MuiDataGrid-cell--editable': {
                            bgcolor: 'rgba(217, 243, 190, 0.3)',
                            ...theme.applyStyles('dark', {
                                bgcolor: '#444',
                            }),
                        },
                        '& .MuiDataGrid-cell': {
                            borderLeft: '1px solid lightgray',
                            cursor: 'pointer',
                        },
                        '& .MuiDataGrid-cell--editable input, & .MuiDataGrid-cell--editable textarea, & .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                            backgroundColor: 'rgb(245, 229, 182)',
                            padding: "0 2px",
                        },
                        '& .MuiDataGrid-cell--editable div:nth-of-type(1)': {
                            padding: "0 10px 0 2px",
                            fontSize: "0.875rem",
                        },
                        '& .MuiDataGrid-cell--editable div:nth-of-type(1) [type="datetime-local"] ': {
                            padding: "0",
                        },
                        '& .MuiDataGrid-columnHeader': {
                            "& div": {
                                whiteSpace: "wrap",
                            }
                        },
                        '& .MuiDataGrid-row.Mui-selected': {
                            backgroundColor: "rgba(25, 118, 210, 0.4) !important",
                        },
                        '& .MuiDataGrid-row.Mui-selected:hover': {
                            backgroundColor: "rgba(25, 118, 210, 0.5) !important",
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: "rgba(141, 155, 142, 0.2) !important",
                        },
                    })}>
                    <DataGridPro
                        sx={{
                            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                                outline: 'none',
                            },
                            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
                            {
                                outline: 'none',
                            },
                            [`& .${gridClasses["row--borderBottom"]}`]:
                            {
                                backgroundColor: "transparent !important",
                            },
                            [`& .${gridClasses.columnHeaders}`]:
                            {
                                backgroundColor: "#bfc4b5",
                            },
                            [`& .${gridClasses.columnHeaderTitle}`]:
                            {
                                fontWeight: 600,
                                letterSpacing: "0.05071em",
                            },
                        }}
                        apiRef={apiRef}
                        getRowId={(row) => row.h300_id}
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={tableRows}
                        columns={columns}
                        editMode="row"
                        getRowHeight={() => 'auto'}
                        // onRowDoubleClick={handleRowClick}
                        slots={{
                            toolbar: CustomToolbar.bind(this, {}),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                        // checkboxSelection
                        disableRowSelectionOnClick
                        loading={loading}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                    />
                </Box>
            </Box>
        </Dialog>


    </>;
}


export const StatisticContent = memo(StatisticContentComponent);
