import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { appStorage } from '../helpers';

import { UserCheck } from '../common/helper/Cheking';
import { urlHistory, urlRecent } from '../common/helper/UrlRouter'

/**
 * Страница по умолчанию
 * @returns страница
 */
export default function PMain(props) {
    const navigate = useNavigate();
    const [role, setRole] = useState('');

    useEffect(() => {
        UserCheck(navigate);
        let buf = appStorage.getItem('authentication');
        buf && setRole(buf.role);
    }, []);

    return (
        <>
            {(() => {
                switch (role) {
                    case 'admin':
                    case 'militaryHospital':
                    case 'nachmedMilitary':
                    case 'receivingSortingDepartment':
                    case 'hospitalDepartment':
                    case 'evacuationDepartment':
                    case 'militaryStatist':
                    case 'evacuationStages':
                    case 'medicalRegistrator':
                    case 'kadrovik':
                        navigate(urlRecent);
                        return;
                    default:
                        break;
                }
            })()}
        </>
    )
}
