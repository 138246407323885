import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import { fields, buttons, dialogs, cards } from '../app/thema';

import { Grid, Card, Typography } from '@mui/material';

import { urlManagement } from '../common/helper/UrlRouter';
import { GetUsersAsync } from '../common/api/Management';
import { LpuEditDlg } from '../components/Dialogs';

import { MilitaryOrganizationsCard } from '../components/Cards';
import { StartIconBtn, EndIconBtn, IconBtn } from '../components/Buttons';

import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import AddBusinessIcon from '@mui/icons-material/AddBusiness'


/**
 * 
 * @returns 
 */
export default function FormaByUsers() {
    const navigate = useNavigate();
    const [userCard, setUserCard] = useState([]);
    const [open, setOpen] = useState(false);
    const [isUpdate, setIsUpdate] = useState(true);


    // Загрузка данных
    const handleDataLoader = async () => {
        let res = await GetUsersAsync();
        let users = [];
        res.users.map((elem) => {
            users.push({
                key: elem.id,
                login: elem.login,
                lic: elem.key,
                isBlock: elem.blocked,
            });
        });
        setUserCard(users);
    }
    const handleClickAccept = () => {
        formik.values.name = '';
        formik.values.fullName = '';

        setIsUpdate(true);
        setOpen(true);
    }
    const handleClickUpdate = (id) => {
        let elem = model.items.find(e => e.key === id);

        formik.values.name = elem.title;
        formik.values.fullName = elem.fullName;

        setIsUpdate(true);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleUndoOut = () => {
        navigate(urlManagement);
    }

    const handleFormAction = (values) => {
    };

    useEffect(() => {
        handleDataLoader();
    }, []);

    const model = {
        header: {
            thema: fields.collection2,
            label: 'Медицинские организации',
        },
        cardItem: {
            thema: cards.collection1,
            fontSize: '16pt',
            img: '../img/MilitaryOrganizations.jpg',
            title: 'Мишин А.В.',
            href: ''
        },
        items: [
            {
                key: 1,
                title: 'ВПСГ "442 ВКГ" МО РФ',
                fullName: 'ВПСГ "442 ВКГ" МО РФ',
            },
            {
                key: 2,
                title: 'Медрота 41 МСП (К)',
                fullName: 'Медрота 41 МСП (К)',
            },
            {
                key: 3,
                title: 'Медрота 41 МСП (М)',
                fullName: 'Медрота 41 МСП (М)',
            },
            {
                key: 4,
                title: 'Медрота 41 МСП (Т)',
                fullName: 'Медрота 41 МСП (Т)',
            }
        ],
        undoBtn: {
            thema: buttons.collection1,
            icon: <UndoOutlinedIcon />,
        },
        addBtn: {
            thema: buttons.collection1,
            icon: <AddBusinessIcon />
        }
    }
    const context = {
        name: '',
        fullName: '',
    }
    const validationSchema = Yup.object().shape({
    });

    const formik = useFormik({
        onSubmit: handleFormAction,
        validationSchema: validationSchema,
        initialValues: context
    });

    // Формирование карточки пользователя
    const CardItem = (props) => {
        const { name, fullName, id } = props;
        return (
            <Grid
                item xs={12} sm={6} md={4} lg={3} xl={2}
            >
                <MilitaryOrganizationsCard
                    thema={model.cardItem.thema} fontSize={model.cardItem.fontSize} img={model.cardItem.img}
                    title={name} fullName={fullName} handleClick={() => handleClickUpdate(id)}
                />
            </Grid>
        )
    }

    return (
        <>
            <Grid
                sx={{
                    mt: '5px',
                    px: '10px',
                }}
                spacing={2}
                container
            >
                <Grid
                    item xs={12} sm={12} md={12} lg={12} xl={12}
                >
                    <Card
                        sx={{
                            py: '15px',
                            borderRadius: '10px'
                        }}
                        variant="outlined"
                    >
                        <Grid
                            container
                        >
                            <Grid
                                sx={{
                                    pl: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.undoBtn.thema} icon={model.undoBtn.icon} handleClick={handleUndoOut}
                                />
                            </Grid>
                            <Grid
                                sx={{
                                    textAlign: 'center',
                                    alignContent: 'center',
                                }}
                                item xs={10} sm={10} md={10} lg={10} xl={10}
                            >
                                <Typography
                                    variant="h4" color={model.header.thema.borderColor}
                                >
                                    {model.header.label}
                                </Typography>
                            </Grid>
                            <Grid
                                sx={{
                                    pr: '15px',
                                    textAlign: 'center'
                                }}
                                item xs={1} sm={1} ms={1} lg={1} xl={1}
                            >
                                <IconBtn
                                    thema={model.addBtn.thema} icon={model.addBtn.icon} handleClick={handleClickAccept}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {model.items.map((elem) => {
                    return (
                        <CardItem 
                            formik={formik}
                            key={elem.key} id={elem.key} title={elem.name} fullName={elem.fullName} 
                        />
                    )
                })}
            </Grid>
            <LpuEditDlg
                formik={formik} open={open} isUpdate={isUpdate}
                handleClose={handleClose}
            />
        </>
    )
}
