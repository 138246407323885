import React, { useState } from 'react';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material';

import TableRowsIcon from '@mui/icons-material/TableRows';

import { DEFAULT_COLOR, STATISTIC_COLORS } from '../../../../constants';
import { PieBlock } from '../PieBlock';
import { LineChartBlock } from '../LineChartBlock';
import { BarChart, BarPlot, ChartsGrid, ChartsTooltip, ChartsXAxis, ChartsYAxis } from '@mui/x-charts';

import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';

const PieRowComponent = ({ rows, statuses, onClickOpenTable, titles, allInStatus }) => {
    let [selected, setSelected] = useState(null);
    const [highlightedItem, setHighLightedItem] = React.useState({});

    let rowsByStatus = rows.filter(row => statuses.includes(row.h300_status) || statuses[0] === allInStatus);
    let pieCount = {};
    let allStatuses = {};
    let total = 0;
    let rowsByType = {};

    rowsByStatus.forEach(row => {
        let type = row.e300_form_type || "";

        if (!pieCount[type]) {
            pieCount[type] = 0;
        }

        pieCount[type]++;
        total++;

        if (!rowsByType[type]) {
            rowsByType[type] = [];
        }

        rowsByType[type].push(row);


        // только для консоли
        let status = row.h300_status || "тип не указан";
        if (!allStatuses[status]) {
            allStatuses[status] = 0;
        }
        allStatuses[status]++;
    });

    // console.log(allStatuses);
    // console.log(pieCount);

    let pieData = Object.keys(pieCount).map(key => {
        return {
            value: pieCount[key],
            label: key,
            // id: key,
            color: STATISTIC_COLORS[key] || DEFAULT_COLOR
        };
    });

    // console.log(pieCount);
    // console.log(pieData);



    /////////////////////////////////////////

    let dates = [];
    let countDataObject = {

    };

    let cats = selected ? [selected] : Object.keys(rowsByType);
    cats.forEach(itemKey => {
        rowsByType[itemKey].forEach(item => {
            let d = dayjs(item.h300_date_action1).format('DD.MM.YYYY');

            if (!countDataObject[itemKey]) {
                countDataObject[itemKey] = {};
            }

            if (!countDataObject[itemKey][d]) {
                countDataObject[itemKey][d] = 0;
            }
            countDataObject[itemKey][d]++;
        });

    });

    rowsByStatus.forEach(item => {
        dates.push(item.h300_date_action1);
    });
    dates.sort();

    let startDate = dates[0];
    let endDate = dates[dates.length];
    let dateArray = [dayjs(startDate).format('DD.MM.YYYY')];
    let series = [];

    Object.keys(countDataObject).forEach(key => {
        const startDate_DATE = dayjs(startDate);
        const endDate_DATE = dayjs(endDate);
        let nextDate = startDate_DATE;
        let countData = [];

        while (nextDate <= endDate_DATE) {
            nextDate = nextDate.add(1, "days");
            let dataKey = dayjs(nextDate).format('DD.MM.YYYY');

            countData.push(countDataObject[key][dataKey] || null);
            dateArray.push(dayjs(nextDate).format('DD.MM.YYYY'));
        }

        series.push({
            data: countData,
            connectNulls: true,
            color: STATISTIC_COLORS[key] || DEFAULT_COLOR,
            // area: true,
            // stack: 'total',
            baseline: 'min',
            showMark: false,
            label: key,
            // stackOffset: 'diverging', 
        });
    });

    const xLabels = dateArray;



    // console.log("dateArray", dateArray);
    // console.log("startDate", startDate);
    // console.log("countDataObject", countDataObject);
    // console.log("series", series);
    // console.log("dates", dates);
    // console.log("rowsByType", rowsByType);


    /////////////////////////////////////////




    const handleClickPie = (event, itemIdentifier, item) => {
        if (itemIdentifier.dataIndex === highlightedItem.dataIndex && highlightedItem.seriesId === itemIdentifier.seriesId) {
            setHighLightedItem({});
            setSelected(null);
        } else {
            setHighLightedItem(itemIdentifier);
            setSelected(item.label);
        }
    }





    if (titles[statuses[0]] === "Выписано/Эвакуировано") {

        console.log(rowsByStatus);

        let location2 = [
        ];
        let status = [
        ];

        let rows = selected ? rowsByType[selected] : rowsByStatus;

        rows.forEach(row => {
            let doc = location2.find(item => item.label === row.h300_location2);
            if (!doc) {
                location2.push(
                    {
                        count: 1,
                        label: row.h300_location2,
                    }
                )
            } else {
                location2 = location2.map(item => {
                    return {
                        ...item,
                        count: item.label === row.h300_location2 ? item.count + 1 : item.count,
                    }
                });
            }
            let doc2 = status.find(item => item.label === row.h300_status);
            if (!doc2) {
                status.push(
                    {
                        count: 1,
                        label: row.h300_status,
                    }
                )
            } else {
                status = status.map(item => {
                    return {
                        ...item,
                        count: item.label === row.h300_status ? item.count + 1 : item.count,
                    }
                });
            }
        });

        location2.sort((a, b) => a.count - b.count);
        status.sort((a, b) => a.count - b.count);
        const xLabels3 = location2.map(d => d.label || "не указано");
        const xLabels4 = status.map(d => d.label || "не указано");

        return <Paper
            elevation={3}
            sx={{
                p: 2,
                mb: 2,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}>

                <Typography variant="h4">{titles[statuses[0]] || statuses[0]}</Typography>

                <Button
                    sx={{ height: "30px" }}
                    variant="outlined"
                    onClick={() => onClickOpenTable({ rows: rowsByStatus })}
                    size="small"
                    startIcon={<TableRowsIcon />}
                >
                    Открыть в виде таблицы
                </Button>
            </Box>
            <Divider />
            <Grid
                container
                sx={{
                    justifyContent: "center",
                }}
            >
                <Grid item>
                    <PieBlock
                        data={pieData}
                        total={total}
                        onItemClick={handleClickPie}
                        highlightedItem={highlightedItem}
                    />
                </Grid>
                <Grid item>
                    <LineChartBlock xLabels={xLabels} countDataObject={countDataObject} series={series} rows={selected ? rowsByType[selected] : rowsByStatus} />
                </Grid>
            </Grid>

            <Grid
                item
            >
                <Typography
                    variant='h5'
                    sx={{ m: 2 }}
                >Статус убытия</Typography>
                <ResponsiveChartContainer
                    // skipAnimation={true}
                    dataset={status}
                    height={xLabels4.length ? xLabels4.length * 25 + 50 : 100}
                    series={[
                        { type: 'bar', dataKey: "count", layout: "horizontal", color: "#47A76A", label: "пациентов" }
                    ]}
                    yAxis={[{
                        data: xLabels4,
                        scaleType: 'band',
                        // valueFormatter: (tt) => { return "" },
                        id: 'y-axis-id',
                    }]}

                    layout="horizontal"
                    margin={{ top: 0, right: 40, bottom: 50, left: 270 }}
                >
                    <BarPlot />

                    <ChartsYAxis
                        position="left"
                        axisId="y-axis-id"
                        disableTicks={true}
                        labelStyle={{
                            textAnchor: "start",
                            transform: 'translateX(-300px) rotate(-90deg) translateX(0px)',
                        }}
                    />
                    <ChartsXAxis label="Кол-во пациентов" position="bottom" />
                    <ChartsTooltip />
                    <ChartsGrid
                        horizontal={true}
                        vertical={false}
                    />
                </ResponsiveChartContainer>
            </Grid>

            <Grid
                item
            >
                <Typography
                    variant='h5'
                    sx={{ m: 2 }}
                >Направление убытия</Typography>
                <ResponsiveChartContainer
                    // skipAnimation={true}
                    dataset={location2}
                    height={xLabels3.length ? xLabels3.length * 20 + 50 : 100}
                    series={[
                        { type: 'bar', dataKey: "count", layout: "horizontal", color: "#47A76A", label: "пациентов" }
                    ]}
                    yAxis={[{
                        data: xLabels3,
                        scaleType: 'band',
                        // valueFormatter: (tt) => { return "" },
                        id: 'y-axis-id',
                    }]}

                    layout="horizontal"
                    margin={{ top: 0, right: 40, bottom: 50, left: 270 }}
                >
                    <BarPlot />

                    <ChartsYAxis
                        position="left"
                        axisId="y-axis-id"
                        disableTicks={true}
                        labelStyle={{
                            textAnchor: "start",
                            transform: 'translateX(-300px) rotate(-90deg) translateX(0px)',
                        }}
                    />
                    <ChartsXAxis label="Кол-во пациентов" position="bottom" />
                    <ChartsTooltip />
                    <ChartsGrid
                        horizontal={true}
                        vertical={false}
                    />
                </ResponsiveChartContainer>
            </Grid>
        </Paper>;
    }





    if (titles[statuses[0]] === "На лечении") {

        ///// Степень тяжести
        let gravity = [
            {
                count: 0,
                variants: ["Легкая", "Лёгкая", "лёгкая", "легкая"],
                color: "lightblue",
            },
            {
                count: 0,
                variants: ["Средняя", "средняя"],
                color: "orange",
            },
            {
                count: 0,
                variants: ["Тяжелая", "Тяжёлая", "тяжёлая", "тяжелая"],
                color: "tomato",
            }
        ];

        ///// Режим пребывания
        let stayMode = [
            {
                count: 0,
                variants: ["Амбулаторно", "амбулаторно"],
                color: "#44944A",
            },
            {
                count: 0,
                variants: ["Стационарно", "стационарно"],
                color: "#3E5F8A",
            },
        ];

        ///// Врачи
        let doctors = [
        ];




        //


        let rows = selected ? rowsByType[selected] : rowsByStatus;



        rows.forEach(row => {
            //
            gravity.forEach((g, index) => {
                if (gravity[index].variants.includes(row.e300_gravity_injury)) {
                    gravity[index].count++;
                }
            });
            //
            stayMode.forEach((g, index) => {
                if (stayMode[index].variants.includes(row.h300_stay_mode)) {
                    stayMode[index].count++;
                }
            });

            //
            let doc = doctors.find(doctor => doctor.label === row.h300_doctor);

            if (!doc) {
                doctors.push(
                    {
                        count: 1,
                        label: row.h300_doctor,
                    }
                )

            } else {
                doctors = doctors.map(doctor => {
                    return {
                        ...doctor,
                        count: doctor.label === row.h300_doctor ? doctor.count + 1 : doctor.count,
                    }
                });
            }

            // if (doctors.find(doctor => {
            //     doctor.variain[row.h300_doctor]

            // }

            // ))


            // }
            // console.log(row.h300_doctor);

        });

        doctors.sort((a, b) => a.count - b.count);

        console.log(gravity);
        console.log(stayMode);
        console.log(doctors);

        let barSeries = gravity.map((g, index) => {
            return {
                data: [g.count],
                label: g.variants[0],
                id: index,
                color: g.color,
            };
        });
        let barSeries2 = stayMode.map((g, index) => {
            return {
                data: [g.count],
                label: g.variants[0],
                id: index,
                color: g.color,
            };
        });
        // let barSeries3 = doctors.map((g, index) => {
        //     return {
        //         data: [g.count],
        //         label: g.label,
        //         id: index,
        //         // color: g.color,
        //     };
        // });
        const xLabels = ['Степень тяжести'];
        const xLabels2 = ['Режим пребывания'];
        // const yLabels3 = ['Распределение по врачам'];
        const xLabels3 = doctors.map(d => d.label || "врач не указан");
        console.log(xLabels3);

        return <Paper
            elevation={3}
            sx={{
                p: 2,
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}>

                <Typography variant="h4">{titles[statuses[0]] || statuses[0]}</Typography>

                <Button
                    sx={{ height: "30px" }}
                    variant="outlined"
                    onClick={() => onClickOpenTable({ rows: rowsByStatus })}
                    size="small"
                    startIcon={<TableRowsIcon />}
                >
                    Открыть в виде таблицы
                </Button>
            </Box>
            <Divider />
            <Grid
                container
                sx={{
                    justifyContent: "center",
                }}
            >
                <Grid item>
                    <PieBlock
                        data={pieData}
                        total={total}
                        onItemClick={handleClickPie}
                        highlightedItem={highlightedItem}
                    />
                </Grid>
                <Box sx={{
                    display: "flex",
                    // width: "817px",
                }}>
                    <Grid item>
                        <BarChart
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                    padding: 0,
                                },
                                loadingOverlay: { message: 'Рассчёт данных' },
                                noDataOverlay: { message: 'Нет данных' },
                            }}
                            width={300}
                            height={300}
                            series={barSeries}
                            xAxis={[{ data: xLabels, scaleType: 'band' }]}
                        />
                    </Grid>
                    <Grid
                        sx={{
                            ml: 4
                        }}
                        item
                    >
                        <BarChart
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'bottom', horizontal: 'middle' },
                                    padding: 0,
                                },
                                loadingOverlay: { message: 'Рассчёт данных' },
                                noDataOverlay: { message: 'Нет данных' },
                            }}
                            width={270}
                            height={300}
                            series={barSeries2}
                            xAxis={[{ data: xLabels2, scaleType: 'band' }]}
                        />
                    </Grid>
                </Box>
            </Grid>
            <Grid
                item
            >
                <Typography
                    variant='h5'
                    sx={{ m: 2 }}
                >Распределение по врачам</Typography>
                <ResponsiveChartContainer
                    // skipAnimation={true}
                    dataset={doctors}
                    // slotProps={{
                    //     loadingOverlay: { message: 'Рассчёт данных' },
                    //     noDataOverlay: { message: 'Нет данных' },
                    // }}
                    // width={1000}
                    height={xLabels3.length ? xLabels3.length * 21 + 50 : 100}
                    series={[
                        { type: 'bar', dataKey: "count", layout: "horizontal", color: "#47A76A", label: "пациентов" }
                    ]}
                    yAxis={[{
                        data: xLabels3,
                        scaleType: 'band',
                        // valueFormatter: (tt) => { return "" },
                        id: 'y-axis-id',
                    }]}

                    layout="horizontal"
                    margin={{ top: 0, right: 40, bottom: 50, left: 150 }}
                >
                    <BarPlot />
                    {/* <BarPlot
                        slots={{
                            bar: (props) => {
                                let dataIndex = props.ownerState?.dataIndex;
                                let label = dataIndex || dataIndex === 0 ? doctors[props.ownerState.dataIndex]?.label || "" : "";
                                let count = dataIndex !== null ? doctors[props.ownerState.dataIndex]?.count || "" : "";

                                label = label || "врач не указан";

                                return (
                                    <g> */}
                    {/* <text
                                        x={props?.style?.x.get() + 5}
                                        y={props?.style?.y.get() + props?.style?.height.get() / 2 + 5}
                                        textAnchor="left"
                                        fill="black"
                                        fontSize={14}
                                        fontWeight={400}
                                        fontFamily='"Roboto","Helvetica","Arial",sans-serif'
                                    >
                                        {label}
                                    </text> */}
                    {/* <rect
                                            width={props?.style?.width.get()}
                                            height={props?.style?.height.get()}
                                            x={props?.style?.x.get()}
                                            y={props?.style?.y.get()}
                                            fill="#47A76A"
                                        ></rect>
                                        <text
                                            x={props?.style?.x.get() + props?.style?.width.get() + 10}
                                            y={props?.style?.y.get() + props?.style?.height.get() / 2 + 5}
                                            textAnchor="left"
                                            fill="black"
                                            fontSize={14}
                                            fontWeight={400}
                                            fontFamily='"Roboto","Helvetica","Arial",sans-serif'
                                        >
                                            {count}
                                        </text> */}
                    {/* </g> */}
                    {/* ) */}
                    {/* }, */}

                    {/* }} */}
                    {/* /> */}

                    <ChartsYAxis
                        // label="Врачи"
                        position="left"
                        axisId="y-axis-id"
                        disableTicks={true}
                        // tickLabelPlacement='middle'
                        labelStyle={{
                            textAnchor: "start",
                            transform: 'translateX(-300px) rotate(-90deg) translateX(0px)',
                        }}
                    />
                    <ChartsXAxis label="Кол-во пациентов" position="bottom" />
                    <ChartsTooltip />
                    <ChartsGrid
                        horizontal={true}
                        vertical={false}
                    />
                </ResponsiveChartContainer>
            </Grid>
        </Paper>;
    }








    return <Paper
        elevation={3}
        sx={{
            p: 2,
            mb: 2,
        }}
    >
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            }}>

            <Typography variant="h4">{titles[statuses[0]] || statuses[0]}</Typography>

            <Button
                sx={{ height: "30px" }}
                variant="outlined"
                onClick={() => onClickOpenTable({ rows: rowsByStatus })}
                size="small"
                startIcon={<TableRowsIcon />}
            >
                Открыть в виде таблицы
            </Button>
        </Box>
        <Divider />
        <Grid
            container
            sx={{
                justifyContent: "center",
            }}
        >
            <Grid item>
                <PieBlock
                    data={pieData}
                    total={total}
                    onItemClick={handleClickPie}
                    highlightedItem={highlightedItem}
                />
            </Grid>
            <Grid item>
                <LineChartBlock xLabels={xLabels} countDataObject={countDataObject} series={series} rows={selected ? rowsByType[selected] : rowsByStatus} />
            </Grid>
        </Grid>
    </Paper>;
}
export const PieRow = React.memo(PieRowComponent);
