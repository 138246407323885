import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appStorage } from '../helpers';

import { drawers } from '../app/thema';
import {
    urlHome,
    urlDocuments,
    urlSignOut,
    urlExport,
    urlHistory,
    urlRecent,
    urlPatientRegistration,
    urlManagement,
    urlEvacuation,
    urlPersonnel,
    urlHospital,
    urlUpload,
    urlStatistic,
} from '../common/helper/UrlRouter';
import { ExportByAllToCsv } from '../common/helper/Export';
import { Drawer, Box, List, Divider } from '@mui/material';
import { DividerDwr, ItemListDwr, ItemCollapseListDwr } from '../components/Drawers';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import CommuteOutlinedOutlineIcon from '@mui/icons-material/CommuteOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';



/**
 * Виджет левого меню
 * @param { Object } props передаваемые параметры
 * @returns виджет
 */
export default function LeftMenu(props) {
    const navigate = useNavigate();
    const items = [
        {
            icon: <HomeOutlinedIcon />,
            text: 'Главная',
            url: urlRecent,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "receivingSortingDepartment",
                "hospitalDepartment",
                "evacuationDepartment",
                "militaryStatist",
                "evacuationStages",                
                "medicalRegistrator",
                "kadrovik",
            ],
        },
        {
            icon: <PendingActionsOutlinedIcon />,
            text: 'Приемно-сортировочное отделение',
            url: urlPatientRegistration,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "receivingSortingDepartment",
                "medicalRegistrator",
            ],
        },
        {
            icon: <LocalHospitalOutlinedIcon />,
            text: 'Госпитальное отделение',
            url: urlHospital,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "hospitalDepartment",
                "medicalRegistrator",
            ],
        },
        {
            icon: <CommuteOutlinedOutlineIcon />,
            text: 'Эвакуационное отделение',
            url: urlEvacuation,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "evacuationDepartment",                
                "medicalRegistrator",
            ],
        },
        {
            icon: <PortraitOutlinedIcon />,
            text: 'Этапы эвакуации',
            url: urlHistory,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "receivingSortingDepartment",               
                "evacuationDepartment",
                "militaryStatist",
                "evacuationStages",
                "medicalRegistrator",
                "kadrovik",                
            ],
        },
        {
            icon: <PeopleAltOutlinedIcon />,
            text: 'Личный состав',
            url: urlPersonnel,
            accesss: [
                "admin",
                "militaryHospital",
                "kadrovik",
            ],
        },
        {
            icon: <ShowChartIcon />,
            text: 'Статистика',
            url: urlStatistic,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
                "militaryStatist",
            ],
        },
        {
            component: <Divider />,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
            ],
        },
        {
            icon: <FileDownloadOutlinedIcon />,
            text: 'Экспорт в CSV',
            url: urlExport,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",                
            ],
        },
        {
            icon: <FileUploadOutlinedIcon />,
            text: 'Загрузка данных',
            url: urlUpload,
            accesss: [
                "admin",
                "militaryHospital",
                "nachmedMilitary",
            ],
        },
        {
            icon: <AdminPanelSettingsIcon />,
            text: 'Управление',
            url: urlManagement,
            accesss: [
                "admin",
                "militaryHospital",
            ],
        },
        {
            component: <Divider />,
            accesss: ["*"],
        },
        {
            icon: <LogoutOutlinedIcon />,
            text: 'Выход',
            url: urlSignOut,
            accesss: ["*"],
            callback: () => { appStorage.removeItem('authentication'); },
        },
    ];

    const roleItems = items.filter(item => item.accesss?.includes(props.role) || item.accesss?.includes("*"));

    const MenuItem = (itemProps) => {
        const {
            text,
            icon,
            url,
            disabled = false,
            thema = drawers.collection1,
            component,
            callback,
        } = itemProps.item;

        if (component) {
            return component;
        }

        const handleClickItem = (event) => {
            callback && callback();
            props.handleClick && props.handleClick();
            url && navigate(url);
            event.preventDefault();
        }

        return <ItemListDwr
            key={text}
            url={url}
            disabled={disabled}
            thema={thema}
            icon={icon}
            text={text}
            handleClickItem={handleClickItem}
        />;
    }

    return (
        <Drawer
            open={props.open}
            onClose={props.handleClick}
        >
            <Box
                sx={{
                    width: 300
                }}
                role='presentation'
            >
                <List>
                    {roleItems.map((item, index) => <MenuItem key={index} item={item} />)}
                </List>
            </Box>
        </Drawer>
    )
}