import React from 'react';

import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
  fetchSendSaveSelected,
  fetchSendSaveRow,
  fetchSendGetStatisticData,
  fetchSendGetTableCurrent,
  fetchSendSavePatientsStatisticStamp,
} from "./statisticAPI";
import { getGridDateOperators, getGridSingleSelectOperators, GridEditSingleSelectCell, useGridApiContext } from "@mui/x-data-grid-pro";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from 'dayjs';

// import { appStorage } from "../../helpers";

const DEFAULT_FILTERS = {
  from: {
    title: "Дата с",
    type: "DateTime",
    value: null,
    listValue: null,
    advancedOptions: [],
    shared: true,
  },
  to: {
    title: "Дата по",
    type: "DateTime",
    value: null,
    listValue: null,
    advancedOptions: [],
    shared: true,
  },

};

export const getSearchType = (advancedOptions = []) => {
  const searchType = advancedOptions[0]?.value || "global";

  return searchType;
}

const getDefaultDateTime = () => {
  let date = {
    from: null,
    to: null,
  }


  // let now = dayjs().unix() * 1000;
  let now = dayjs();
  let nowDayStart = dayjs(now).startOf('day');

  let nowDayStart_0500 = dayjs(nowDayStart).add(5, 'hours');
  let nowDayStart_1630 = dayjs(nowDayStart).add(16, 'hours').add(30, 'minutes');

  if (now > nowDayStart_1630) {
    console.log("now > nowDayStart_1630");
    date.from = dayjs(nowDayStart_1630).add(-11, 'hours').add(-30, 'minutes');
    date.to = nowDayStart_1630;

  } else if (now < nowDayStart_1630 && now > nowDayStart_0500){
    console.log("now < nowDayStart_1630 && now > nowDayStart_0500");
    date.from = dayjs(nowDayStart_0500).add(-12, 'hours').add(-30, 'minutes');
    date.to = nowDayStart_0500;
  } else if (now < nowDayStart_0500){
    console.log("now < nowDayStart_0500");
    date.from = dayjs(nowDayStart_0500).add(-1, 'day');
    date.to = dayjs(nowDayStart_1630).add(-1, 'day');
  } else {
    console.log("else");
    date.from = dayjs(now).add(-1, 'day');
    date.to = now;
  }

  return date;
  
}

let defaultDateTime = getDefaultDateTime();

const initialState = {
  list: {},
  filterObject: {},
  tableCurrent: {},
  currentPage: "",
  currentPatientId: null,
  currentHistoryItem: {},
  printList: [],
  sharedFilters: DEFAULT_FILTERS,
  filterGroups: {
    "1": {
      filterGroup: 1,
      filterGroupName: "Даты",
      fields: ["from", "to"],
    },
  },
  filterValues: {
    ...defaultDateTime,
    // from: dayjs().add(-30, "day").unix() * 1000,
    // to: dayjs().unix() * 1000,
  },
  filters: DEFAULT_FILTERS,
  patientEdit: {
    disease: [],
    lifesAnamnez: [],
  },
  statisticData: {
    table: {
      columns: [],
      rows: [],
    },
  },
  loading: {
    statisticData: false,
  }
};
function CustomFilterSelect(props, items) {
  const { item, applyValue, focusElementRef } = props;

  const ratingRef = React.useRef(null);
  // React.useImperativeHandle(focusElementRef, () => ({
  // focus: () => {
  //   ratingRef.current
  //     .querySelector(`input[value="${Number(item.value) || ''}"]`)
  //     .focus();
  // },
  // }));

  const handleFilterChange = (event, newValue) => {
    console.log(event, newValue);
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
      }}
    >

      <FormControl fullWidth>
        <InputLabel id="simple-select-label">Значение</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={item.value}
          label="Значение"
          onChange={handleFilterChange}
          ref={ratingRef}
          variant='standard'
        >
          {items.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}

export const sendSavePatientsStatisticStamp = createAsyncThunk(
  "statistic/sendSavePatientsStatisticStamp",
  async (props = {}, { getState }) => {
    const {
      data = {},
      success = () => { }
    } = props;

    const response = await fetchSendSavePatientsStatisticStamp({
      data,
      success
    });

    return response;
  }
);


export const sendGetStatisticData = createAsyncThunk(
  "statistic/sendGetStatisticData",
  async (props = {}, { getState }) => {
    const {
      data,
      success = () => { }
    } = props;

    const response = await fetchSendGetStatisticData({
      data,
      success
    });

    return response;
  }
);

export const sendGetTableCurrent = createAsyncThunk(
  "statistic/sendGetTableCurrent",
  async (props = {}, { getState }) => {
    const {
      success = () => { }
    } = props;

    const response = await fetchSendGetTableCurrent({
      success
    });

    return response;
  }
);

export const sendSaveSelected = createAsyncThunk(
  "statistic/sendSaveSelected",
  async (props = {}, { getState }) => {
    const {
      selected,
      success = () => { }
    } = props;

    const response = await fetchSendSaveSelected({
      data: {
        guids: selected
      },
      success
    });

    return response;
  }
);

export const sendSaveRow = createAsyncThunk(
  "statistic/sendSaveRow",
  async (props = {}, { getState }) => {
    const {
      row,
      success = () => { }
    } = props;
    // const { auth, history } = getState();

    const response = await fetchSendSaveRow({
      data: {
        row
      },
      success
    });

    return response;
  }
);



export const statisticSlice = createSlice({
  name: "statistic",
  initialState,
  reducers: {
    updateSharedFilters: (state, action) => {
      state.sharedFilters = {
        ...action.payload,
        ...state.sharedFilters,
      }
    },
    clearSearchResult: (state, action) => {
      state.searchResult[action.payload] = [];
    },
    updateFilters: (state, action) => {
      const filterKey = action.payload.name;
      // let filterObject;
      // let filterObject2;

      // // if (state.sharedFilters.hasOwnProperty(filterKey)) {
      // filterObject = state.sharedFilters[filterKey] || {};
      // // } else {
      // filterObject2 = state.filters[filterKey] || {};
      // // }

      // if (action.payload.value) {
      //   filterObject.value = action.payload.value;
      //   filterObject2.value = action.payload.value;
      // }
      // if (action.payload.realValue) {
      //   filterObject.realValue = action.payload.realValue;
      //   filterObject2.realValue = action.payload.realValue;
      // }

      state.filterValues = {
        ...state.filterValues,
        [filterKey]: action.payload.value,
      }
    },
    resetStatisticData: (state, action) => {
      state.statisticData = initialState.statisticData;
    },
    resetTableCurrent: (state, action) => {
      state.statisticData = initialState.tableCurrent;
    },

    setPrintList: (state, action) => {
      state.printList = action.payload || initialState.printList;
    },
    setFilters: (state, action) => {
      state.filters = action.payload || initialState.filters;
    },

  },

  extraReducers: (builder) => {
    builder


      .addCase(sendGetStatisticData.pending, (state) => {
        state.status = "loading";
        state.loading.statisticData = true;
      })

      .addCase(sendGetStatisticData.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;

        console.log(action.payload);

        let responseData = {};
        let responseRows = action.payload?.rows || {};
        let responseColumns = action.payload?.columns || [];


        console.log(responseColumns);

        const fieldTypes = {
          Guid: "string",
          String: "string",
          DateTimeOffset: "dateTime",
          Date: "date",

          // не используются
          number: "number",
          // date: "date",
          // dateTime: "dateTime",
          boolean: "boolean",
          singleSelect: "singleSelect",
        }

        function CustomTypeEditComponent(props) {
          // console.log(props);
          const apiRef = useGridApiContext();


          const handleValueChange = async (event) => {

            // console.log(newRow);

            let currentValue = await apiRef.current.getCellValue(props.id, props.dependablefield);
            let currentParams = await apiRef.current.getCellParams(props.id, props.dependablefield);
            let currentDependencyValues = currentParams.colDef.dependencyValues || {};
            let currentListObject = currentDependencyValues[event.target.value] || currentParams.colDef.values;

            // В новом списке не может быть текущего значения, тогда очищаем
            if (!currentListObject.includes(currentValue)) {
              await apiRef.current.setEditCellValue({
                id: props.id,
                field: props.dependablefield,
                value: '',
              });
            }
          };

          return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
        }

        let widths = {
          jp_token_number: 140,
          fio: 180,
          h300_date_action1: 150,
          h300_doctor: 150,
          e300_gravity_injury: 110,
          h300_status: 150,
        };

        let flexs = {
          h300_diagnosis_clinical: 1,
        };

        let minWidths = {
          h300_diagnosis_clinical: 150,
        };

        responseColumns = responseColumns.map(column => {
          let type = column.fieldType;
          let valueOptions = [];

          let width;
          let flex;
          let minWidth;

          if (column.values && column.values.length) {
            type = "singleSelect";
            valueOptions = column.values;
            // valueOptions = column.values && column.values.length && !column.values.includes("") && !column.values.includes("-") ? ["", ...column.values] : column.values;
          }

          if (widths[column.field]) {
            width = widths[column.field];
          }
          if (flexs[column.field]) {
            flex = flexs[column.field];
          }
          if (minWidths[column.field]) {
            minWidth = minWidths[column.field];
          }




          /**
           * >>>>>>
           */


          let h300_notes = responseRows && responseRows.length ? responseRows.map(row => row.h300_notes) : [];
          h300_notes = [...new Set(h300_notes)];

          if (column.field === 'h300_notes') {
            return {
              ...column,
              filterOperators: getGridSingleSelectOperators()
                .map((operator) => ({
                  ...operator,
                  InputComponent: operator.InputComponent
                    ? (props) => CustomFilterSelect(props, h300_notes)
                    : undefined,
                })),
            };

          }

          if (column.fieldType === 'DateTimeOffset') {
            return {
              width,
              ...column,
              hideable: !column.hiddenPermanent,
              type: fieldTypes[type] || "string",
              filterOperators: getGridDateOperators()
                .map((operator) => {

                  // заменяем "меньше чем", на кастомный, потому что в нативном баг 
                  if (operator.value !== "before") {
                    return operator;
                  } else {
                    return {
                      ...operator,
                      getApplyFilterFn: (filterItem, column) => {
                        console.log(filterItem.field, filterItem.value, filterItem.operator)
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                          return null;
                        }

                        return (value, row, column, apiRef) => {
                          if (!dayjs(value).isValid() || !dayjs(filterItem.value).isValid()) {
                            return true;
                          }

                          let date1 = new Date(value).getTime();
                          let date2 = new Date(filterItem.value).getTime();

                          return date1 < date2;
                        };
                      },
                    }
                  }
                }),
              valueFormatter: (value) => {
                if (column.fieldType === "DateTimeOffset") {
                  return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
                }
                if (column.fieldType === "Date") {
                  return value && new Date(value).toLocaleDateString(undefined, {

                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  });
                }
                return value;
              },
            };

          }
          /**
           * <<<<<<
           */

          // console.log(column);
          return {
            width,
            flex,
            minWidth,
            ...column,
            hideable: !column.hiddenPermanent,
            // headerName: column.headerName + " " + width,
            type: fieldTypes[type] || "string",
            // valueGetter: column.fieldType === "DateTimeOffset" || column.fieldType === "Date" ? (value) => value && new Date(value) : null,
            valueFormatter: (value) => {
              if (column.fieldType === "DateTimeOffset") {
                return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
              }
              if (column.fieldType === "Date") {
                return value && new Date(value).toLocaleDateString(undefined, {

                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                });
              }
              return value;
            },
            // cellClassName: () => column.editable ? "editable-cell" : "",
            valueOptions: ({ field, id, row }) => {

              // Предполагаем, что это выпадающий список в фильтре с зависимыми полями. Собираем туда все варианты, которые могут быть.
              if (column?.dependencyField && (!id && !row)) {
                let column = responseColumns.find(column => column.field === field) || {};
                let dependencyValues = column.dependencyValues || {};
                let allDependencyOptions = [...column.values];
                let entries = Object.values(dependencyValues);

                entries.forEach(entry => {
                  allDependencyOptions = [...entry, ...allDependencyOptions];
                });

                allDependencyOptions = [...new Set(allDependencyOptions)]; // оставляем уникальные значения
                allDependencyOptions = allDependencyOptions.filter(option => option !== ""); // пустое удаляем, т.к. движок добавит своё пустое

                return allDependencyOptions.length ? allDependencyOptions : valueOptions;
              }

              if (column.dependencyField) {
                let dependencyValue = row ? row[column.dependencyField] : "";

                if (dependencyValue) {
                  return column.dependencyValues[dependencyValue] || valueOptions;
                } else {
                  return [];
                }

                // console.log(row);
                // console.log(column.dependencyField);
                // console.log(row[column.dependencyField]);
                // console.log(column.dependencyValues);
                // console.log(column.dependencyValues[dependencyValue]);
              }

              return valueOptions;
            },
            renderEditCell: column.field === "h300_status" ? (params) => <CustomTypeEditComponent {...params} dependablefield={"h300_location2"} /> : undefined,
          }
        });


        console.log(responseColumns);

        // responseColumns.unshift({
        //   dependencyField: null,
        //   disableColumnMenu: true,
        //   dependencyValues: null,
        //   editable: false,
        //   editableFields: null,
        //   field: "count",
        //   fieldType: "String",
        //   groupChange: false,
        //   headerName: "№",
        //   hidden: false,
        //   sortable: false,
        //   toPrint: true,
        //   type: "string",
        //   width: 40,
        //   disableReorder: true,
        //   // valueGetter: (value, row) => { return i++; console.log(value, row) },
        //   renderCell: (value, row) => {
        //     let index = value.api.getRowIndexRelativeToVisibleRows(value.id) || 0;

        //     /**
        //      * @todo попробовать достать отсортированный список и наложить на него отфильтрованный список (его непонятно где взять)
        //      */
        //     // let index = value.api.state.sorting.sortedRows.findIndex((x)=>x===value.id);
        //     return index + 1;
        //   },
        //   values: null,
        // });


        let filters = {};

        const getType = (type) => {
          switch (type) {
            case "date":
              return "Date";

            case "dateTime":
              return "DateTime";

            default:
              return "SelectMulti";
          }
        }

        responseColumns.forEach(column => {
          if (column.filter) {
            filters[column.field] = {
              title: column.headerName,
              type: getType(column.type),
              value: undefined,
              listValue: column.values ? column.values.map((item, index) => ({
                id: index,
                name: item,
              })) : [],
              advancedOptions: [],
              shared: true,
            };



            if (column.filterGroup) {
              let filterGroup = state.filterGroups[column.filterGroup] || {
                filterGroup: column.filterGroup,
                filterGroupName: column.filterGroupName,
              };

              if (!filterGroup.fields) {
                filterGroup.fields = []
              }

              state.filterGroups[column.filterGroup] = {
                ...filterGroup,
                fields: [...new Set([...filterGroup.fields, column.field])],
              }

            }

          }
        });

        state.filters = {
          ...state.filters,
          ...filters,
        };
        state.sharedFilters = {
          ...filters,
          ...state.sharedFilters,
        };





        /**
         * FILTER
         */
        // console.log(responseRows);
        // console.log(state.sharedFilters);
        // console.log(current(state));
        // console.log(current(state.filterValues));

        let filterValues = { ...state.filterValues } || {};

        function capitalizeFirstLetter(val) {
          val = val === "300" ? "Ранение" : val;

          if (val) {
            val = String(val).toLocaleLowerCase();
            return String(val).charAt(0).toUpperCase() + String(val).slice(1);
          } else {
            return "";
          }
        }

        responseRows = responseRows.map(row => {
          let e300_form_type = row.e300_form_type ? capitalizeFirstLetter(row.e300_form_type) : "";

          return {
            ...row,
            e300_form_type
          };
        });

        responseRows = responseRows.filter(row => {

          // console.log(row.e300_form_type);
          for (let key in filterValues) {

            // dates
            if (filterValues[key] && row.hasOwnProperty(key) && state.filters[key]?.type === "DateTime") {
              if (dayjs().isValid(row[key])) {
                if (!(dayjs(row[key]) > dayjs(filterValues[key]) && dayjs(row[key]) < dayjs(filterValues[key]).add(1, "day"))) {
                  return false;
                }
              }
            }


            // multiple values
            if (filterValues[key] && Array.isArray(filterValues[key]) && filterValues[key].length) {
              let values = filterValues[key].map(item => item.name);

              if (!values.includes(row[key])) {
                return false;
              }
            }
          }
          return true;
        });


        responseData = {
          "table": {
            "columns": responseColumns,
            "rows": responseRows,
          }
        };

        // responseRows.length = 10;

        console.log(responseData);


        state.statisticData = responseData;
      })

      .addCase(sendGetStatisticData.rejected, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;
      })

      .addCase(sendGetTableCurrent.pending, (state) => {
        state.status = "loading";
        state.loading.statisticData = true;
      })

      .addCase(sendGetTableCurrent.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;

        console.log(action.payload);

        let responseData = {};
        let responseRows = action.payload?.rows || {};
        let responseColumns = action.payload?.columns || [];


        console.log(responseColumns);

        const fieldTypes = {
          Guid: "string",
          String: "string",
          DateTimeOffset: "dateTime",
          Date: "date",

          // не используются
          number: "number",
          // date: "date",
          // dateTime: "dateTime",
          boolean: "boolean",
          singleSelect: "singleSelect",
        }

        function CustomTypeEditComponent(props) {
          // console.log(props);
          const apiRef = useGridApiContext();


          const handleValueChange = async (event) => {

            // console.log(newRow);

            let currentValue = await apiRef.current.getCellValue(props.id, props.dependablefield);
            let currentParams = await apiRef.current.getCellParams(props.id, props.dependablefield);
            let currentDependencyValues = currentParams.colDef.dependencyValues || {};
            let currentListObject = currentDependencyValues[event.target.value] || currentParams.colDef.values;

            // В новом списке не может быть текущего значения, тогда очищаем
            if (!currentListObject.includes(currentValue)) {
              await apiRef.current.setEditCellValue({
                id: props.id,
                field: props.dependablefield,
                value: '',
              });
            }
          };

          return <GridEditSingleSelectCell onValueChange={handleValueChange} {...props} />;
        }

        let widths = {
          jp_token_number: 140,
          fio: 180,
          h300_date_action1: 150,
          h300_doctor: 150,
          e300_gravity_injury: 110,
          h300_status: 150,
        };

        let flexs = {
          h300_diagnosis_clinical: 1,
        };

        let minWidths = {
          h300_diagnosis_clinical: 150,
        };

        responseColumns = responseColumns.map(column => {
          let type = column.fieldType;
          let valueOptions = [];

          let width;
          let flex;
          let minWidth;

          if (column.values && column.values.length) {
            type = "singleSelect";
            valueOptions = column.values;
            // valueOptions = column.values && column.values.length && !column.values.includes("") && !column.values.includes("-") ? ["", ...column.values] : column.values;
          }

          if (widths[column.field]) {
            width = widths[column.field];
          }
          if (flexs[column.field]) {
            flex = flexs[column.field];
          }
          if (minWidths[column.field]) {
            minWidth = minWidths[column.field];
          }




          /**
           * >>>>>>
           */


          let h300_notes = responseRows && responseRows.length ? responseRows.map(row => row.h300_notes) : [];
          h300_notes = [...new Set(h300_notes)];

          if (column.field === 'h300_notes') {
            return {
              ...column,
              filterOperators: getGridSingleSelectOperators()
                .map((operator) => ({
                  ...operator,
                  InputComponent: operator.InputComponent
                    ? (props) => CustomFilterSelect(props, h300_notes)
                    : undefined,
                })),
            };

          }

          if (column.fieldType === 'DateTimeOffset') {
            return {
              width,
              ...column,
              hideable: !column.hiddenPermanent,
              type: fieldTypes[type] || "string",
              filterOperators: getGridDateOperators()
                .map((operator) => {

                  // заменяем "меньше чем", на кастомный, потому что в нативном баг 
                  if (operator.value !== "before") {
                    return operator;
                  } else {
                    return {
                      ...operator,
                      getApplyFilterFn: (filterItem, column) => {
                        console.log(filterItem.field, filterItem.value, filterItem.operator)
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                          return null;
                        }

                        return (value, row, column, apiRef) => {
                          if (!dayjs(value).isValid() || !dayjs(filterItem.value).isValid()) {
                            return true;
                          }

                          let date1 = new Date(value).getTime();
                          let date2 = new Date(filterItem.value).getTime();

                          return date1 < date2;
                        };
                      },
                    }
                  }
                }),
              valueFormatter: (value) => {
                if (column.fieldType === "DateTimeOffset") {
                  return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
                }
                if (column.fieldType === "Date") {
                  return value && new Date(value).toLocaleDateString(undefined, {

                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  });
                }
                return value;
              },
            };

          }
          /**
           * <<<<<<
           */

          // console.log(column);
          return {
            width,
            flex,
            minWidth,
            ...column,
            hideable: !column.hiddenPermanent,
            // headerName: column.headerName + " " + width,
            type: fieldTypes[type] || "string",
            // valueGetter: column.fieldType === "DateTimeOffset" || column.fieldType === "Date" ? (value) => value && new Date(value) : null,
            valueFormatter: (value) => {
              if (column.fieldType === "DateTimeOffset") {
                return value && new Date(value).toLocaleString(undefined, { timeStyle: "short", dateStyle: "short", });
              }
              if (column.fieldType === "Date") {
                return value && new Date(value).toLocaleDateString(undefined, {

                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                });
              }
              return value;
            },
            // cellClassName: () => column.editable ? "editable-cell" : "",
            valueOptions: ({ field, id, row }) => {

              // Предполагаем, что это выпадающий список в фильтре с зависимыми полями. Собираем туда все варианты, которые могут быть.
              if (column?.dependencyField && (!id && !row)) {
                let column = responseColumns.find(column => column.field === field) || {};
                let dependencyValues = column.dependencyValues || {};
                let allDependencyOptions = [...column.values];
                let entries = Object.values(dependencyValues);

                entries.forEach(entry => {
                  allDependencyOptions = [...entry, ...allDependencyOptions];
                });

                allDependencyOptions = [...new Set(allDependencyOptions)]; // оставляем уникальные значения
                allDependencyOptions = allDependencyOptions.filter(option => option !== ""); // пустое удаляем, т.к. движок добавит своё пустое

                return allDependencyOptions.length ? allDependencyOptions : valueOptions;
              }

              if (column.dependencyField) {
                let dependencyValue = row ? row[column.dependencyField] : "";

                if (dependencyValue) {
                  return column.dependencyValues[dependencyValue] || valueOptions;
                } else {
                  return [];
                }

                // console.log(row);
                // console.log(column.dependencyField);
                // console.log(row[column.dependencyField]);
                // console.log(column.dependencyValues);
                // console.log(column.dependencyValues[dependencyValue]);
              }

              return valueOptions;
            },
            renderEditCell: column.field === "h300_status" ? (params) => <CustomTypeEditComponent {...params} dependablefield={"h300_location2"} /> : undefined,
          }
        });


        console.log(responseColumns);

        // responseColumns.unshift({
        //   dependencyField: null,
        //   disableColumnMenu: true,
        //   dependencyValues: null,
        //   editable: false,
        //   editableFields: null,
        //   field: "count",
        //   fieldType: "String",
        //   groupChange: false,
        //   headerName: "№",
        //   hidden: false,
        //   sortable: false,
        //   toPrint: true,
        //   type: "string",
        //   width: 40,
        //   disableReorder: true,
        //   // valueGetter: (value, row) => { return i++; console.log(value, row) },
        //   renderCell: (value, row) => {
        //     let index = value.api.getRowIndexRelativeToVisibleRows(value.id) || 0;

        //     /**
        //      * @todo попробовать достать отсортированный список и наложить на него отфильтрованный список (его непонятно где взять)
        //      */
        //     // let index = value.api.state.sorting.sortedRows.findIndex((x)=>x===value.id);
        //     return index + 1;
        //   },
        //   values: null,
        // });


        let filters = {};

        responseColumns.forEach(column => {
          if (column.filter) {
            filters[column.field] = {
              title: column.headerName,
              type: column.type === "date" || column.type === "dateTime" ? "DateTime" : "SelectMulti",
              value: undefined,
              listValue: column.values ? column.values.map((item, index) => ({
                id: index,
                name: item,
              })) : [],
              advancedOptions: [],
              shared: true,
            };



            if (column.filterGroup) {
              let filterGroup = state.filterGroups[column.filterGroup] || {
                filterGroup: column.filterGroup,
                filterGroupName: column.filterGroupName,
              };

              if (!filterGroup.fields) {
                filterGroup.fields = []
              }

              state.filterGroups[column.filterGroup] = {
                ...filterGroup,
                fields: [...new Set([...filterGroup.fields, column.field])],
              }

            }

          }
        });

        state.filters = {
          ...state.filters,
          ...filters,
        };
        state.sharedFilters = {
          ...filters,
          ...state.sharedFilters,
        };





        /**
         * FILTER
         */
        // console.log(responseRows);
        // console.log(state.sharedFilters);
        // console.log(current(state));
        // console.log(current(state.filterValues));

        let filterValues = { ...state.filterValues } || {};

        function capitalizeFirstLetter(val) {
          val = val === "300" ? "Ранение" : val;

          if (val) {
            val = String(val).toLocaleLowerCase();
            return String(val).charAt(0).toUpperCase() + String(val).slice(1);
          } else {
            return "";
          }
        }

        responseRows = responseRows.map(row => {
          let e300_form_type = row.e300_form_type ? capitalizeFirstLetter(row.e300_form_type) : "";

          return {
            ...row,
            e300_form_type
          };
        });

        responseRows = responseRows.filter(row => {

          // console.log(row.e300_form_type);
          for (let key in filterValues) {

            // dates
            if (filterValues[key] && row.hasOwnProperty(key) && state.filters[key]?.type === "DateTime") {
              if (dayjs().isValid(row[key])) {
                if (!(dayjs(row[key]) > dayjs(filterValues[key]) && dayjs(row[key]) < dayjs(filterValues[key]).add(1, "day"))) {
                  return false;
                }
              }
            }


            // multiple values
            if (filterValues[key] && Array.isArray(filterValues[key]) && filterValues[key].length) {
              let values = filterValues[key].map(item => item.name);

              if (!values.includes(row[key])) {
                return false;
              }
            }
          }
          return true;
        });


        responseData = {
          "table": {
            "columns": responseColumns,
            "rows": responseRows,
          }
        };

        // responseRows.length = 10;

        console.log(responseData);


        state.tableCurrent = responseData;
      })

      .addCase(sendGetTableCurrent.rejected, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;
      })



      .addCase(sendSaveSelected.pending, (state) => {
        state.status = "loading";
        state.loading.statisticData = true;
      })

      .addCase(sendSaveSelected.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;




        state.statisticData.table.rows = state.statisticData?.table?.rows?.map(item => {
          return {
            ...item,
            h300_status: action.meta?.arg?.selected?.includes(item.h300_id) ? "Эвакуирован" : item.h300_status
          }
        });


      })

      .addCase(sendSaveSelected.rejected, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;
      })



      .addCase(sendSavePatientsStatisticStamp.pending, (state) => {
        state.status = "loading";
        state.loading.statisticData = true;
      })

      .addCase(sendSavePatientsStatisticStamp.fulfilled, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;
      })

      .addCase(sendSavePatientsStatisticStamp.rejected, (state, action) => {
        state.status = "idle";
        state.loading.statisticData = false;
      })

      ;
  },
});

export const {
  resetStatisticData,
  resetTableCurrent,
  setPrintList,
  setFilters,
  updateFilters,
  clearSearchResult,
} = statisticSlice.actions;


export const selectFilterGroups = (state) => state.statistic.filterGroups;
export const selectFilterValues = (state) => state.statistic.filterValues;
export const selectStatisticData = (state) => state.statistic.statisticData;
export const selectTableCurrent = (state) => state.statistic.tableCurrent;
export const selectFilters = (state) => state.statistic.filters;
export const selectSharedFilters = (state) => state.statistic.sharedFilters;
export const selectLoading = (state) => state.statistic.loading.statisticData;
export const selectPrintList = (state) => state.statistic.printList;

export default statisticSlice.reducer;
