import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import {
    Divider,
    Typography,
    Stack,
    Box,
    Button,
} from '@mui/material';

import { lightGreen } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";

import { PatientInfoEdit } from "../PatientInfoEdit/PatientInfo";

import {
    selectPatientData,
} from "../../historySlice";
import { useNavigate } from "react-router-dom";
import { urlPersonnel } from "../../../../common/helper/UrlRouter";

const PatientInfoComponent = ({ blockStyles = {}, editable = false }) => {
    
    const navigate = useNavigate();
    const patientData = useSelector(selectPatientData);

    const [openModal, setOpenModal] = useState(false);

    const {
        id,
        suid,
        fio,
        birthday,
        militaryUnitCode,
        militaryUnit,
        militaryFunction,
        militaryRank,
        events,
        contingent,
    } = patientData;

    let patientLastRowInfo = [];
    let rankCont = [];

    if (militaryUnit) {
        patientLastRowInfo.push(militaryUnit);
    }
    if (militaryFunction) {
        patientLastRowInfo.push(militaryFunction);
    }
    if (militaryRank) {
        rankCont.push(militaryRank);
    }
    if (contingent) {
        rankCont.push(contingent);
    }


    const handleClickEdit = () => {        
        navigate(`${urlPersonnel}/${patientData.id}/edit`);
        // setOpenModal(true);
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    return <Box
        sx={{

        }}
    >
        <PatientInfoEdit
            handleCloseModal={handleCloseModal}
            openModal={openModal}
        />
        <Stack
            spacing={2}
            sx={{
                textAlign: "center",
                m: 1,
                ...blockStyles,
            }} >
            <Typography variant="h6" sx={{ margin: "0 !important" }}>{suid}</Typography>
            <Typography variant="h5" sx={{ margin: "0 !important" }}>{fio} </Typography>
            <Typography variant="body2" sx={{ margin: "0 !important" }}>{birthday ? new Date(birthday).toLocaleDateString() : ""}</Typography>
            <Typography variant="body2" sx={{ margin: "0 !important" }}>{rankCont.join(", ")}</Typography>
            <Typography variant="body2" sx={{ margin: "0 !important" }}>{militaryUnit || ""}</Typography>
            <Typography variant="body2" sx={{ margin: "0 !important" }}>{militaryUnitCode || ""}</Typography>

            {editable && <Button
                variant="outlined"
                endIcon={<EditIcon />}
                onClick={handleClickEdit}
                color="inherit"
            >
                Редактировать
            </Button>}
        </Stack>
        {/* <Divider /> */}
    </Box>;
}

export const PatientInfo = memo(PatientInfoComponent);
