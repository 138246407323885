import React from 'react';
import 'dayjs/locale/ru';

import { Box } from '@mui/material';

import { SKIP_ANIMATION } from '../../constants';
import { LineChart } from '@mui/x-charts';

const LineChartBlockComponent = ({ xLabels, series, rows }) => {
    return <Box
        sx={{
            textAlign: "end",
            m: 1
        }}>

        <LineChart
            skipAnimation={SKIP_ANIMATION}
            width={800}
            height={360}
            series={series}
            slotProps={{ legend: { hidden: true } }}
            xAxis={[{
                scaleType: 'point',
                data: xLabels,
            }]}
        />
    </Box>;
}

export const LineChartBlock = React.memo(LineChartBlockComponent);
