import React, { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Stack, Box, Typography } from '@mui/material';

import { Date, DateTime, SearchStringMulti, SelectMulti, SearchString } from "../Filters2/components";
import { Loader, Button } from "../../../../UI";

import {
    selectFilters,
    selectFilterValues,
    selectFilterGroups,
    selectSharedFilters,
    updateFilters,
    selectLoading,
    sendGetStatisticData,
    // sendGetContent,
    // selectLoadingContent
} from "../../statisticSlice";
import dayjs from "dayjs";


const Filters2Component = () => {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);
    const filterValues = useSelector(selectFilterValues);
    const filterGroups = useSelector(selectFilterGroups);
    const loading = useSelector(selectLoading);

    console.log(loading);
    // const { content: loadingContent } = useSelector(selectLoadingContent);

    const handleChangeFilter = useCallback((props) => {
        dispatch(updateFilters(props));
    }, [dispatch]);

    const handleSubmit = () => {
        // dispatch(sendGetContent({ page: 0 }));
        if (filters.from && filters.to) {
            dispatch(sendGetStatisticData({
                data: {
                    "dateStart": dayjs(filterValues.from || undefined).toISOString(),
                    "dateEnd": dayjs(filterValues.to || undefined).toISOString(),
                    filters: filterValues
                }
            }));
        }
    }

    const FilterItem = ({ field }) => {



        let filter = filters[field] || {};

        if (filterValues[field]) {
            filter = {
                ...filter,
                value: filterValues[field],
            };
        }

        switch (filter.type) {

            case "Date": // 
                return <Date
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;

            case "DateTime": // 
                return <DateTime
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;


            case "SearchStringMulti": // returntype: "StringDictionary"
                return <SearchStringMulti
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;


            case "SelectMulti": // returntype: "StringArray"
                return <SelectMulti
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;
            case "Select": // returntype: "StringArray"
                return <SelectMulti
                    multiple={false}
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;


            case "SearchString": // returntype: "StringDictionary"
                return <SearchString
                    key={field}
                    name={field}
                    {...filter}
                    onChange={handleChangeFilter}
                />;


            default:
                return null;
        }
    }


    const FilterGroup = ({ group }) => {
        return <>
            <Typography
                variant="h6"
                sx={{
                    // backgroundColor: "lightgray",
                }}>{group.filterGroup}. {group.filterGroupName || ""}</Typography>
            {group.fields?.length ? group.fields.map(field => <FilterItem field={field} key={field} />) : null}
        </>;
    }


    console.log(filters);
    return <Loader loading={loading} variant="cover">
        <Stack spacing={2} sx={{ pt: 2, pr: 1 }}>
            {Object.values(filterGroups).map((group, index) => {
                return <FilterGroup group={group} key={index} />;
            })}

            <Box sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "#FFF",
                zIndex: 1,
                pb: 1
            }}>
                <Stack direction="row" spacing={1}>
                    {/* <DownloadButton /> */}
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit}
                        loading={false}
                        sx={{ m: 0, mt: "25px !important" }}
                    >Применить</Button>
                </Stack>
            </Box>
        </Stack>
    </Loader>
}

export const Filters2 = memo(Filters2Component);
