import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    urlDef,
    urlSignIn,
    urlSignOut,
    urlHome,
    urlRecent,
    urlHospital,
    urlPatientRegistration,
    urlFormaDeath,
    urlFormaInjury,
    urlFormaDiseases,
    urlFormaTrauma,
    urlFormaNuclear,
    urlFormaBacterial,
    urlFormaChemical,
    urlFormaBurn,
    urlFormaFrostbite,
    urlFormaInfectious,
    urlExport,
    urlHistory,
    urlEvacuation,
    urlPersonnel,
    urlManagement,
    urlMilitaryUsers,
    urlMilitaryOrganizations,
    urlUpload,
    urlStatistic,
} from '../common/helper/UrlRouter';

import PAuthentication from '../pages/PAuthentication';
import PMain from '../pages/PMain';
import PRegistration from '../pages/PRegistration';
import PExport from '../pages/PExport';
import PHistory from '../pages/PHistory';
import PPersonnel from '../pages/PPersonnel';
import PEvacuation from '../pages/PEvacuation';
import PRecent from '../pages/PRecent';
import PHospital from '../pages/PHospital';
import PManagement from '../pages/PManagement';
import PUpload from '../pages/PUpload';
import PStatistic from '../pages/PStatistic';


/**
 * Маршрутизация приложения
 * @returns готовая страница
 */
export default function router() {
    return (
        <Routes>
            <Route path={urlHome} element={<PMain />} />

            <Route path={urlDef} element={<PAuthentication />} />
            <Route path={urlSignIn} element={<PAuthentication />} />
            <Route path={urlSignOut} element={<PAuthentication />} />

            <Route path={urlRecent} element={<PRecent />} />
            <Route path={urlHospital} element={<PHospital />} />

            <Route path={urlPatientRegistration} element={<PRegistration />} >
                <Route path={urlPatientRegistration + "/:eventType?/:token?/:personnelId?/:eventId?"} element={<PRegistration />} />
            </Route>

            <Route path={urlExport} element={<PExport />} />
            <Route path={urlUpload} element={<PUpload />} />
            <Route path={urlStatistic} element={<PStatistic />} />
            <Route path={urlEvacuation} element={<PEvacuation />} />
            <Route path={urlPersonnel} element={<PPersonnel />} >
                <Route path={urlPersonnel + "/:patientId?/:action?"} element={<PPersonnel />} />
            </Route>
            <Route path={urlHistory} element={<PHistory />} >
                <Route path={urlHistory + "/:patientId?/:eventId?/:historyId?"} element={<PHistory />} />
            </Route>

            <Route path={urlManagement} element={<PManagement step={0} />} />
            <Route path={urlMilitaryUsers} element={<PManagement step={1} />} />
            <Route path={urlMilitaryOrganizations} element={<PManagement step={2} />} />
        </Routes>
    )
}