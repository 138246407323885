import { API_URLS } from "../../constants";
import { POST, appStorage, FILE } from "../../helpers";


let authentication = appStorage.getItem('authentication') || {};
let authParams = {
  authkey: authentication.authkey || "",
  key: authentication.key || "",
}



export function fetchSendGetUploadData({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.GetUploadData,
    data: {
      ...data,
      id: data.id,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}

export function fetchSendSaveSelected({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.PatientsUploadStatus,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}


export function fetchSendSaveRow({ data = {}, success = null }) {

  return POST({
    // path: API_URLS.main.GetHistoryPatientsByUser,
    path: API_URLS.main.PatientsUpload,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}





export function fetchSendSavePatientsUploadStamp({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.PatientsUploadStamp,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}


export function fetchSendAnalysisFile({ data = {}, file = {}, success = null }) {
  console.log(data);
  return FILE({
    path: API_URLS.upload.AnalysisByFile,
    data: {
      file,
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}

export function fetchSendLoadFile({ data = {}, success = null, always = null }) {
  return POST({
    path: API_URLS.upload.LoadFile,
    // path: API_URLS.main.GetPSOData,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
    always: (data) => {
      always && always(data);
    },
  });
}

export function fetchSendLoaderByProgress({ data = {}, success = null }) {
  return POST({
    path: API_URLS.upload.LoaderByProgress,
    data: {
      ...data,
      ...authParams,
    },
    success: (data) => {
      success && success(data);
    },
  });
}



